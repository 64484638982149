import { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'

import { useAuthStore } from '../../../stores/auth/useAuthStore'

import cardImage from '../../../assets/img/billing/card.png'
import sepaImage from '../../../assets/img/billing/sepa.png'
import DropdownItem from '../../../components/dropdown/DropdownItem'
import DropdownMenu from '../../../components/dropdown/DropdownMenu'
import Text from '../../../components/Text'
import { IStripePaymentMethod } from '../../../interfaces/models/user'

interface IComponentProps {
    type: string
    brand: any
    last4: ReactNode
}

interface IPaymentMethodSelectProps {
    onClickHandler?: (e: any) => void
    paymentMethodId?: string
}

const cardImg = <img
    src={cardImage}
    alt=''
/>
const sepaImg = <img
    src={sepaImage}
    alt=''
/>

const Component: FunctionComponent<IComponentProps> = ({ type, brand, last4 }) => {
    return <CardComponent>
        {type === 'card' ? cardImg : sepaImg}
        <TextWrapper>
            <Text $black><Text $black $capitalize>{brand}</Text> ending with {last4}</Text>
        </TextWrapper>
    </CardComponent>
}

const PaymentMethodSelect: FunctionComponent<IPaymentMethodSelectProps> = ({ paymentMethodId, onClickHandler }) => {
    const { store: { subscription, user } } = useAuthStore()
    const [prefilledValue, setPrefilledValue] = useState<IStripePaymentMethod | undefined>()


    useEffect(() => {
        let currentValue = subscription?.paymentMethods.find((stripePaymentMethod: IStripePaymentMethod) => paymentMethodId ? stripePaymentMethod.id === paymentMethodId : stripePaymentMethod.isDefault)
        if (!currentValue) currentValue = user?.stripePaymentMethods?.find((stripePaymentMethod: IStripePaymentMethod) => paymentMethodId ? stripePaymentMethod.id === paymentMethodId : stripePaymentMethod.isDefault)
        if (!paymentMethodId) onClickHandler?.(currentValue?.id)
        setPrefilledValue(currentValue)
    }, [paymentMethodId])

    return (
        <SelectWrapper>
            <DropdownMenu title={prefilledValue && <Component type={prefilledValue.type} brand={prefilledValue.brand} last4={prefilledValue.last4} />}>
                {subscription?.paymentMethods?.length > 0 && subscription?.paymentMethods.map((method: any) => (
                    <DropdownItem key={method.id} onClickHandler={() => onClickHandler?.(method.id)}>
                        <Component type={method.type} brand={method.brand} last4={method.last4} />
                    </DropdownItem>
                ))}
                {subscription?.paymentMethods?.length === 0 && user?.stripePaymentMethods && user?.stripePaymentMethods.map((method: any) => (
                    <DropdownItem key={method.id} onClickHandler={() => onClickHandler?.(method.id)}>
                        <Component type={method.type} brand={method.brand} last4={method.last4} />
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </SelectWrapper>
    )
}
const CardComponent = styled.div`
    display: flex;
    align-items: center;
`
const SelectWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -10px;

    & .dropdown {
        width: 100%;
        border: 2px solid ${({ theme: { quinaryColor } }) => `${quinaryColor};`};
        margin: 5px 0 0 0;
        padding: 5px;
    }

    & .dropdown-menu {
        width: 100%;
        transform: translate3d(0px, 50px, 0px) !important;
    }

    & .dropdown-toggle {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        ${({ theme: { quaternaryColor } }) => `color: ${quaternaryColor};`}
    }
`
const TextWrapper = styled.div`
    margin-left: 10px;
`

export default PaymentMethodSelect