import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import Text from '../../../components/Text'
import { styles } from '../../../styles/themes/style'
import BillingBox from './BillingBox'
import crownImage from '../../../assets/img/billing/crown.png'
import confettiImage from '../../../assets/img/billing/confetti.png'
import { IBillingPlan } from '../../../interfaces/models/billingPlan'


interface IBillingPlanProps {
    plan: IBillingPlan,
    saveAmount?: number
}

interface IBoxHeaderProps {
    $green?: number
}

const BillingPlan: FunctionComponent<IBillingPlanProps> = ({ plan, saveAmount }) => {
    //hooks
    const [priceMonthly, setPriceMonthly] = useState("")
    const [priceString, setPriceString] = useState("")


    useEffect(() => {
        setPriceString(calculatePrice().toFixed(2))
        setPriceMonthly(calculatePricePerMonth().toFixed(2))
    }, [plan])

    //constants
    const crownImg = <img
        src={crownImage}
        alt='crown-image'
    />

    const confettiImg = <img
        src={confettiImage}
        alt='confetti-image'
        width={'25px'}
    />

    const calculatePrice = (): number => {
        return Math.round(plan?.price) / 100
    }

    const calculatePricePerMonth = (): number => {
        return plan?.interval === 'year' ? calculatePrice() / 12 : calculatePrice()
    }

    return (
        <BillingBox $noPadding $width={'350px'}>
            <BillingBoxHeader $green={saveAmount}>
                {saveAmount && <Text $white>{`Save ${plan?.currency === 'usd' ? '$' : '€'}${saveAmount.toFixed(2)}`}</Text>}
            </BillingBoxHeader>
            <BillingBoxInfo>
                <StyledImage>{plan?.interval === 'month' ? crownImg : confettiImg}</StyledImage>
                <Text $black $heading3>{`${plan?.currency === 'usd' ? '$' : '€'}${priceMonthly} / per month`}</Text>
                <Text $faded>{`${plan?.currency === 'usd' ? '$' : '€'}${priceString} billed ${plan?.interval === 'month' ? 'monthly' : 'annualy'} as a reccuring payment`}</Text>
            </BillingBoxInfo>
        </BillingBox>
    )
}
const BillingBoxHeader = styled.div<IBoxHeaderProps>`
    border-radius: 14px 14px 0px 0px;
    width: 100%;
    background: ${({ $green, theme: { secondaryColor } }) => $green ? styles.colors.green600 : secondaryColor};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    height: 26px;

`
const BillingBoxInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding: 10px;
    min-height: 170px;
`
const StyledImage = styled.div`
    border-radius: 50%;
    width: 54px;
    height: 54px;
    background: ${styles.colors.primary600};
    display: flex;
    justify-content: center;
    align-items: center;
`

export default BillingPlan