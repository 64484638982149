import { FormEvent, FunctionComponent, ReactNode, useEffect, useState } from "react"
import styled from "styled-components"
import Button from "../../../components/Button";
import FormControl from "../../../components/FormControl";
import Input from "../../../components/Input";
import Tag from "../../../components/Tag";
import Text from "../../../components/Text";
import { useAppHandlers } from "../../../services/actions/app/useAppHandlers";
import { useBillingHandlers } from "../../../services/actions/billing/useBillingHandlers";
import { useAppActions } from "../../../stores/application/useAppActions";
import { useBillingStore } from "../../../stores/billing/useBillingStore";
import { styles } from '../../../styles/themes/style'



interface IDiscountFormState {
    couponCode: string,
    disabled: boolean
}

interface IDiscountFormBoxProps {
    discountAmount: number,
    currency?: string
}

const DiscountFormBox: FunctionComponent<IDiscountFormBoxProps> = ({ discountAmount, currency = '$' }) => {

    const formOriginalState: IDiscountFormState = {
        couponCode: "",
        disabled: true
    }
    //hooks
    const { applyCouponHandler, removeCouponHandler } = useBillingHandlers()
    const { setToast } = useAppActions()
    //const { setConfirmationModal } = useAppHandlers() //todo: add confirmation when removing coupon
    const { store: { appliedCoupon } } = useBillingStore() //todo: return coupon code with the coupon values
    const [discountFormState, setDiscountFormState] = useState<IDiscountFormState>({ ...formOriginalState })
    useEffect(() => {
        if (appliedCoupon) resetForm()
    }, [appliedCoupon])


    const resetForm = () => setDiscountFormState({ ...formOriginalState })
    const validateForm = (): string => {
        return discountFormState.couponCode.length > 0 ? "" : "Coupon code empty!"//todo: create Form interface with errors and utility validations
    }
    const submitFormHandler = (event: FormEvent) => {
        event.preventDefault()
        const errorMessage = validateForm()
        if (!errorMessage) {
            applyCouponHandler(discountFormState.couponCode)
        } else {
            setToast({ type: 'error', message: errorMessage })
        }
    }

    return (
        <StyledDiscountContainer>
            <AppliedDiscountLine>
                <AppliedDiscountLabel>
                    <Text $black $bold>Discount:</Text>
                    {appliedCoupon && <Tag $borderColor={styles.colors.primary600} $color={styles.colors.primary100} title={appliedCoupon?.couponCode} onDestructiveClickHandler={() => removeCouponHandler()} />}
                </AppliedDiscountLabel>
                {appliedCoupon && <Text $black $bold>-{currency}{discountAmount.toFixed(2)}</Text>}
            </AppliedDiscountLine>
            <StyledForm onSubmit={submitFormHandler}>
                <FormControl $width="170px" $orientation="row">
                    <Input prefilledValue={discountFormState.couponCode} placeholder="Coupon Code" onChangeHandler={value => setDiscountFormState({ ...discountFormState, couponCode: value, disabled: false })} />
                </FormControl>
                <Button $type="blue" $bigButton type="submit" disabled={discountFormState.disabled}>Apply Coupon</Button>
            </StyledForm>
        </StyledDiscountContainer>

    )
}
const StyledDiscountContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 6px;
`
const AppliedDiscountLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const AppliedDiscountLabel = styled.div`
    display: flex;
    gap: 12px;
`
const StyledForm = styled.form`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export default DiscountFormBox