import React, { FunctionComponent } from "react"

type TSVGType = { [key: string]: JSX.Element }

interface ISvgFragmentProps {
    type?: string
}

const SVG: TSVGType = {
    label: <svg className='svg' width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
        <path className='path' fill='#8C96AD' fillRule='evenodd' d='M11.879 4c.3 0 .701.15.96.35l.1.09 6.622 6.62a1.5 1.5 0 0 1 .103 2.008l-.103.114-6.379 6.379a1.5 1.5 0 0 1-2.007.103l-.114-.103-6.622-6.622c-.212-.212-.39-.602-.43-.926L4 11.879V5.5a1.5 1.5 0 0 1 1.356-1.493L5.5 4h6.379zM10 8.5a1.5 1.5 0 0 0-1.356-1.493L8.5 7a1.5 1.5 0 0 0-.144 2.993L8.5 10A1.5 1.5 0 0 0 10 8.5z' />
    </svg>,
    pin: <svg className='svg' width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
        <path className='path' fill='#8C96AD' fillRule='evenodd' d='m3.405 20.214.639-1.917a.358.358 0 0 1 .073-.118l2.77-2.77-3.622-3.622a.904.904 0 0 1 0-1.278c1.363-1.363 3.446-1.424 5.318-.74l3.476-2.824-1.124-1.124a.904.904 0 0 1 0-1.278l1.278-1.278a.904.904 0 0 1 1.278 0l7.244 7.244a.904.904 0 0 1 0 1.278l-1.278 1.278a.904.904 0 0 1-1.278 0l-1.124-1.124-2.824 3.476c.69 1.892.612 3.967-.74 5.318a.904.904 0 0 1-1.278 0l-3.622-3.622-2.77 2.77a.301.301 0 0 1-.118.073l-1.917.64a.302.302 0 0 1-.381-.382z' />
    </svg>,
    unpin: <svg className='svg' width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
        <path className='path' fill='#8C96AD' fillRule='evenodd' d='m12.224 20.845.75-1.688a.369.369 0 0 0 .026-.126v-3.656h4.25c.414 0 .75-.378.75-.844 0-1.8-1.187-3.214-2.687-3.998l-.382-4.158h1.319c.414 0 .75-.378.75-.844V3.844c0-.466-.336-.844-.75-.844h-8.5c-.414 0-.75.378-.75.844V5.53c0 .466.336.844.75.844h1.319l-.382 4.158C7.17 11.326 6 12.747 6 14.53c0 .466.336.844.75.844H11v3.656a.31.31 0 0 0 .026.126l.75 1.688c.092.207.356.207.448 0z' />
    </svg>,
    funnel: <svg className='svg' width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
        <path className='path' fill='#8C96AD' fillRule='evenodd' d='M13.75 18.343v-7.165l5.057-5.058A.657.657 0 0 0 18.343 5H5.657a.657.657 0 0 0-.464 1.12l5.057 5.058v5.634c0 .215.104.415.28.538l2.187 1.53a.657.657 0 0 0 1.033-.537z' />
    </svg>,
    delete: <svg className='svg' width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
        <path className='path' fill='#8C96AD' fillRule='evenodd' d='M16.5 20a1.5 1.5 0 0 0 1.5-1.5V8h.5a.5.5 0 0 0 .5-.5V7a.5.5 0 0 0-.5-.5h-2.575l-1.063-1.772c-.22-.365-.715-.668-1.156-.72L13.576 4h-3.151c-.47 0-1.045.326-1.287.728L8.075 6.5H5.5A.5.5 0 0 0 5 7v.5a.5.5 0 0 0 .5.5H6v10.5A1.5 1.5 0 0 0 7.5 20h9zM14.175 6.5h-4.35l.545-.909c.03-.05.102-.091.16-.091h2.939c.059 0 .13.04.16.091l.546.909zm2.325 12h-9V8h9v10.5zM14.125 17a.375.375 0 0 0 .375-.375v-6.75a.375.375 0 0 0-.375-.375h-.75a.375.375 0 0 0-.375.375v6.75c0 .207.168.375.375.375h.75zm-3.5 0a.375.375 0 0 0 .375-.375v-6.75a.375.375 0 0 0-.375-.375h-.75a.375.375 0 0 0-.375.375v6.75c0 .207.168.375.375.375h.75z' />
    </svg>,
    unread: <svg className='svg' width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
        <path className='path' fill='#8C96AD' fillRule='evenodd' d='M18.5 18c.828 0 1.5-.71 1.5-1.584V7.73c0-.507-.224-.957-.575-1.247-.778-.644-14.072-.644-14.85 0-.35.29-.575.74-.575 1.247v8.686C4 17.291 4.672 18 5.5 18h13zM12 14.833c-1.117.007-2.25-1.082-2.883-1.57a526.527 526.527 0 0 1-3.291-2.536.273.273 0 0 1-.057-.362l.283-.436a.242.242 0 0 1 .354-.063c.714.557 1.734 1.344 3.3 2.544.527.405 1.569 1.378 2.294 1.367.725.011 1.768-.963 2.294-1.367 1.566-1.2 2.586-1.987 3.3-2.544a.242.242 0 0 1 .354.063l.283.436a.274.274 0 0 1-.057.362c-.715.557-1.733 1.342-3.29 2.536-.635.488-1.768 1.577-2.884 1.57z' />
    </svg>,
    read: <svg className='svg' width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
        <path className='path' fill='#8C96AD' fillRule='evenodd' d='M18.5 20a1.5 1.5 0 0 0 1.5-1.5v-8.227c0-.48-.224-.906-.575-1.18-.778-.61-1.422-1.106-5.13-3.798C13.768 4.912 12.724 3.989 12 4c-.725-.01-1.768.912-2.294 1.295-3.71 2.692-4.353 3.187-5.131 3.797-.35.275-.575.701-.575 1.18V18.5A1.5 1.5 0 0 0 5.5 20h13zM12 17c-1.117.007-2.25-1.025-2.883-1.487a524.22 524.22 0 0 1-3.291-2.402.25.25 0 0 1-.057-.343l.283-.412a.25.25 0 0 1 .354-.06 516.93 516.93 0 0 0 3.3 2.41c.527.383 1.569 1.305 2.294 1.294.725.01 1.768-.911 2.294-1.295a516.222 516.222 0 0 0 3.3-2.408.25.25 0 0 1 .354.06l.283.411a.25.25 0 0 1-.057.343c-.715.528-1.733 1.272-3.29 2.402-.635.463-1.768 1.495-2.884 1.487z' />
    </svg>,
    star: <svg className='svg' width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
        <path className='path' fill='#8C96AD' fillRule='evenodd' d='m8.275 19.885 4.084-2.147 4.085 2.147c.725.381 1.59-.232 1.45-1.053l-.782-4.547 3.304-3.219a1 1 0 0 0-.554-1.706l-4.565-.666-2.04-4.137c-.366-.738-1.426-.747-1.795 0l-2.04 4.137-4.566.666a1 1 0 0 0-.553 1.706l3.303 3.219-.781 4.547a1 1 0 0 0 1.45 1.053z' />
    </svg>,
    unstar: <svg className='svg' width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
        <path className='path' fill='#8C96AD' fillRule='evenodd' d='M11.462 4.557c.35-.708 1.319-.737 1.73-.11l.064.11 2.04 4.137 4.566.666a1 1 0 0 1 .632 1.62l-.078.086-3.304 3.219.782 4.547c.134.784-.649 1.378-1.35 1.099l-.1-.046-4.085-2.147-4.084 2.147a1 1 0 0 1-1.463-.943l.013-.11.781-4.547-3.303-3.219a1.001 1.001 0 0 1 .44-1.683l.113-.023 4.566-.666 2.04-4.137zm2.506 5.965L12.36 7.259l-1.608 3.263-3.602.524 2.607 2.54-.617 3.584 3.22-1.692 3.22 1.692-.616-3.583 2.607-2.541-3.602-.524z' />
    </svg>,
    archive: <svg className='svg' width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path className='path' fill="#8C96AD" fillRule="evenodd" d="M19.5 8c.275 0 .5-.225.5-.5V6c0-.553-.447-1-1-1H5c-.553 0-1 .447-1 1v1.5c0 .275.225.5.5.5h15zM18 19c.553 0 1-.447 1-1V9H5v9c0 .553.447 1 1 1h12zm-4.375-7h-3.25a.376.376 0 0 1-.375-.375v-.25c0-.206.169-.375.375-.375h3.25c.206 0 .375.169.375.375v.25a.376.376 0 0 1-.375.375z" />
    </svg>,
    unarchive: <svg className='svg' width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path className='path' fill="#8C96AD" fillRule="evenodd" d="M18.5 5h-13A1.5 1.5 0 0 0 4 6.5V9c0 .275.225.5.5.5H5V18c0 .553.447 1 1 1h12c.553 0 1-.447 1-1V9.5h.5c.275 0 .5-.225.5-.5V6.5A1.5 1.5 0 0 0 18.5 5zm-1 12.5h-11v-8h11v8zm1-9.5h-13V6.5h13V8zm-8.125 4.5h3.25a.376.376 0 0 0 .375-.375v-.75a.376.376 0 0 0-.375-.375h-3.25a.376.376 0 0 0-.375.375v.75c0 .206.169.375.375.375z" />
    </svg>,
    sidebarClose: <svg className='svg' width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path className='path' d="m11.32 6.193-5.134 5.34a.679.679 0 0 0 0 .933l5.133 5.34a.617.617 0 0 0 .896 0l.6-.622a.678.678 0 0 0 0-.932L8.747 12l4.068-4.252a.679.679 0 0 0 0-.932l-.6-.623a.617.617 0 0 0-.896 0zm5 0-5.134 5.34a.679.679 0 0 0 0 .933l5.133 5.34a.617.617 0 0 0 .896 0l.6-.622a.678.678 0 0 0 0-.932L13.747 12l4.068-4.252a.679.679 0 0 0 0-.932l-.6-.623a.617.617 0 0 0-.896 0z" fill="#8C96AD" fillRule="evenodd" />
    </svg>,
    refresh: <svg className='svg' width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path className='path' d="M15.56 9.279c.15 0 .273-.123.273-.274V4.44a.274.274 0 0 0-.273-.273h-1.08a.274.274 0 0 0-.272.286l.09 1.886c-.93-1.098-2.855-1.99-4.295-1.99h-.007a5.65 5.65 0 0 0-5.55 4.599.274.274 0 0 0 .269.323h1.118c.12 0 .239-.095.266-.211.408-1.694 2.154-3.07 3.897-3.07 1.179 0 2.669.794 3.326 1.772l-2.311-.11h-.013a.274.274 0 0 0-.274.273v1.08c0 .151.123.274.274.274h4.562zM5.52 15.833a.274.274 0 0 0 .272-.287l-.094-1.881c.931 1.096 2.854 1.986 4.293 1.986h.005a5.65 5.65 0 0 0 5.55-4.599.274.274 0 0 0-.269-.323h-1.118a.287.287 0 0 0-.266.211c-.407 1.695-2.153 3.07-3.896 3.07h-.004c-1.179 0-2.667-.794-3.324-1.772l2.32.11h.013a.274.274 0 0 0 .268-.217l.005-.056v-1.08a.274.274 0 0 0-.273-.273H4.44a.274.274 0 0 0-.273.273v4.565c0 .15.122.273.273.273h1.08z" fill="#8C96AD" fillRule="evenodd" />
    </svg>,
    profile: <svg className='svg' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path className='path' d="M12 13c2.484 0 4.5-2.016 4.5-4.5S14.484 4 12 4 7.5 6.016 7.5 8.5 9.516 13 12 13zm5.5 7c.828 0 1.5-.672 1.5-1.5v-.8c0-2.319-1.881-4.2-4.2-4.2-.9 0-1.325.5-2.8.5-1.472 0-1.9-.5-2.8-.5-2.319 0-4.2 1.881-4.2 4.2v.8c0 .828.672 1.5 1.5 1.5h11z" />
    </svg>,
    show: <svg className='svg' width="20" height="20" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg">
        <path className='path' d="M12 5c-7.633 0-9.927 6.617-9.948 6.684L1.946 12l.105.316C2.073 12.383 4.367 19 12 19s9.927-6.617 9.948-6.684l.106-.316-.105-.316C21.927 11.617 19.633 5 12 5zm0 11c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4z" />
        <path className='path' d="M12 10c-1.084 0-2 .916-2 2s.916 2 2 2 2-.916 2-2-.916-2-2-2z" />
    </svg>
    ,
    hide: <svg className='svg' xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 52 52">
        <g className='path'>
            <path d="M51.8,25.1c-1.6-3.2-3.7-6.1-6.3-8.4L37,25.1c0,0.3,0,0.6,0,0.9c0,6.1-4.9,11-11,11c-0.3,0-0.6,0-0.9,0
                l-5.4,5.4c2,0.4,4.1,0.7,6.2,0.7c11.3,0,21.1-6.6,25.8-16.1C52.1,26.3,52.1,25.7,51.8,25.1z"/>
            <path d="M48.5,5.6l-2.1-2.1C45.8,2.9,44.7,3,44,3.8l-7.3,7.3C33.4,9.7,29.8,9,26,9C14.7,9,4.9,15.6,0.2,25.1
                c-0.3,0.6-0.3,1.3,0,1.8c2.2,4.5,5.5,8.2,9.6,11L3.8,44c-0.7,0.7-0.8,1.8-0.3,2.4l2.1,2.1C6.2,49.1,7.3,49,8,48.2L48.2,8
                C49,7.3,49.1,6.2,48.5,5.6z M15,26c0-6.1,4.9-11,11-11c2,0,3.8,0.5,5.4,1.4l-3,3C27.6,19.2,26.8,19,26,19c-3.9,0-7,3.1-7,7
                c0,0.8,0.2,1.6,0.4,2.4l-3,3C15.5,29.8,15,28,15,26z"/>
        </g>
    </svg>,
    close: <svg className='svg' width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className='path' fillRule="evenodd" clipRule="evenodd" d="M1.30708 7.8832L3.99988 5.18997L6.69292 7.88295C6.84868 8.03869 7.10142 8.03869 7.25743 7.88295L7.8832 7.25721C8.03894 7.10145 8.03894 6.84872 7.8832 6.69271L5.18989 4L7.88295 1.30704C8.03869 1.15128 8.03869 0.898553 7.88295 0.74255L7.25718 0.116801C7.10142 -0.0389338 6.84868 -0.0389338 6.69267 0.116801L3.99988 2.81003L1.30684 0.117046C1.15108 -0.0386897 0.898337 -0.0386897 0.742328 0.117046L0.116805 0.74255C-0.038935 0.898285 -0.038935 1.15104 0.116805 1.30704L2.80987 4L0.116805 6.69296C-0.038935 6.84872 -0.038935 7.10145 0.116805 7.25745L0.742572 7.8832C0.898337 8.03893 1.15108 8.03893 1.30708 7.8832Z" fill="#5964FF" />
    </svg>,
    checkboxEmpty: <svg className='svg' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.1704 8C15.1704 12.0076 11.9388 15.25 7.9602 15.25C3.98164 15.25 0.75 12.0076 0.75 8C0.75 3.9924 3.98164 0.75 7.9602 0.75C11.9388 0.75 15.1704 3.9924 15.1704 8Z" fill="white" stroke="#8C96AD" strokeWidth="1.5" />
    </svg>,
    checkboxChecked: <svg className='svg' viewBox="142.269 33.49 15.92 16" width="15.92" height="16" xmlns="http://www.w3.org/2000/svg">
        <g transform="matrix(1, 0, 0, 1, 18.863392, 0.460083)">
            <ellipse cx="131.366" cy="41.03" rx="7.96" ry="8" fill="#5964FF" />
            <path fillRule="evenodd" clipRule="evenodd" d="M 130.553 44.145 L 135.893 39.005 C 136.075 38.83 136.075 38.547 135.893 38.373 L 135.237 37.741 C 135.055 37.566 134.761 37.566 134.58 37.741 L 130.225 41.933 L 128.192 39.976 C 128.011 39.801 127.717 39.801 127.535 39.976 L 126.879 40.608 C 126.697 40.782 126.697 41.065 126.879 41.24 L 129.897 44.145 C 130.078 44.32 130.372 44.32 130.553 44.145 Z" fill="white" />
        </g>
    </svg>,
    checkmark: <svg viewBox="20.473 7.299 86 86" width="86" height="86" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M 59.415 65.571 L 85.442 40.267 C 86.326 39.408 86.326 38.015 85.442 37.155 L 82.241 34.044 C 81.357 33.184 79.924 33.184 79.041 34.044 L 57.815 54.68 L 47.905 45.045 C 47.022 44.186 45.589 44.186 44.705 45.045 L 41.504 48.157 C 40.62 49.016 40.62 50.409 41.504 51.269 L 56.215 65.571 C 57.099 66.43 58.531 66.43 59.415 65.571 Z" fill="#16D090" />
    </svg>,
    plus: <svg viewBox="153.753 64.386 12 12" width="12" height="12" xmlns="http://www.w3.org/2000/svg">
        <line x1="154.789" y1="70.319" x2="164.731" y2="70.319" stroke="#5964FF" strokeWidth="2" strokeLinecap="round" />
        <line x1="159.628" y1="65.435" x2="159.628" y2="75.337" stroke="#5964FF" strokeWidth="2" strokeLinecap="round" />
    </svg>,
    minus: <svg width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="1" y1="1" x2="13" y2="1" stroke="#5964FF" strokeWidth="2" strokeLinecap="round" />
    </svg>

}

const SvgFragment: FunctionComponent<ISvgFragmentProps> = ({ type }) => {
    return (
        <>{type && SVG[type]}</>
    )
}

export default SvgFragment