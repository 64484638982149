import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { styles } from '../../../styles/themes/style'
import Switch from '../../../components/Switch'
import Text from '../../../components/Text'
import { useBillingHandlers } from '../../../services/actions/billing/useBillingHandlers'
import { useBillingStore } from '../../../stores/billing/useBillingStore'
import BillingBox from './BillingBox'
import BillingPlan from './BillingPlan'
import DiscountFormBox from './DiscountFormBox'
import InputNumber from '../../../components/InputNumber'
import FormControl from '../../../components/FormControl'
import { useBillingActions } from '../../../stores/billing/useBillingActions'
import { useAuthStore } from '../../../stores/auth/useAuthStore'
import { IBillingPlan } from '../../../interfaces/models/billingPlan'
import debounce from 'lodash.debounce'


interface IBillingPlansProps {
    discountForm?: boolean,
    updatePlan?: boolean
}

interface IStyledTotalsProps {
    $borderTop?: boolean,
    $borderBottom?: boolean,
    $flexDirection?: string
}

const BillingPlans: FunctionComponent<IBillingPlansProps> = ({ discountForm = false, updatePlan = false }) => {
    //hooks
    const { setSelectedBillingPlanHandler, calculateUpcomingPaymentHandler } = useBillingHandlers()
    const { store: { selectedBillingPlan, billingPlans, appliedCoupon, quantityFormState, licenceQuantity, upcomingPayment } } = useBillingStore()
    const { setLicenceQuantity, setQuantityFormState } = useBillingActions()
    const { store: { context, subscription, workspace, validSubscription, trial } } = useAuthStore()


    const [saveAmount, setSaveAmount] = useState<number>(0)
    const [discountAmount, setDiscountAmount] = useState<number>(0)
    const [totalAmount, setTotalAmount] = useState<number>(0)

    useEffect(() => {
        setSaveAmount(calculateSaveAmount())
    }, [billingPlans])
    useEffect(() => {
        setDiscountAmount(calculateDiscountAmount())
        if (updatePlan && licenceQuantity && subscription?.isValid) {
            //todo: don't let switch of the plans if already on that plan
            calculateUpcomingPaymentHandler()
        }
    }, [selectedBillingPlan, appliedCoupon, licenceQuantity])
    useEffect(() => {
        setTotalAmount(calculateTotalAmount())
    }, [discountAmount, licenceQuantity, selectedBillingPlan])
    useEffect(() => {
        setDiscountAmount(upcomingPayment?.discounts?.length ? upcomingPayment.discounts[0] : 0) //only one discount
    }, [upcomingPayment])

    //methods
    const calculateSaveAmount = (): number => {
        if (billingPlans?.length > 1) {
            const annualPrice = billingPlans.find((billingPlan: IBillingPlan) => billingPlan.interval === 'year')
            const monthlyPrice = billingPlans.find((billingPlan: IBillingPlan) => billingPlan.interval === 'month')
            return (monthlyPrice?.price * 12 - annualPrice?.price) / 100
        }
        return 0
    }
    const calculateDiscountAmount = (): number => {
        if (appliedCoupon?.appliesToProducts?.includes(selectedBillingPlan?.productId)) {
            if (appliedCoupon?.amountOff) return appliedCoupon?.amountOff
            return appliedCoupon?.percentOff ? (selectedBillingPlan?.price / 100 * appliedCoupon.percentOff) / 100 : 0
        }
        return 0
    }
    const calculateTotalAmount = (): number => {
        return licenceQuantity * (selectedBillingPlan?.price / 100 - discountAmount)
    }
    const setLicenceQuantityHandler = debounce((value: number) => {
        if (quantityFormState) {
            quantityFormState.property = value.toString()
            setQuantityFormState({ ...quantityFormState })
            let calculateLicenceQuantity = subscription?.quantity && workspace?.seatsUsed && subscription?.quantity > workspace?.seatsUsed
                ? ((subscription?.quantity ?? 0) + value)
                : (workspace?.seatsUsed ?? 0) + value
            setLicenceQuantity(calculateLicenceQuantity)
        }
    }, 500)
    //validSubscription && !trial && selectedBillingPlan?.priceId === subscription?.priceId ? value : 

    return (
        <Container>
            <BillingBox>
                <ToggleContainer>
                    <Text $black $active={selectedBillingPlan?.interval === 'month'} $bold={selectedBillingPlan?.interval === 'month'}>Bill monthly</Text>
                    <Switch $bothStatesOn prefilledValue={selectedBillingPlan?.interval !== 'month' ?? true} onClickHandler={value => setSelectedBillingPlanHandler(value ? "year" : "month")} $disabled={context === "individual" && updatePlan} />
                    <Text $black $active={selectedBillingPlan?.interval === 'year'} $bold={selectedBillingPlan?.interval === 'year'}>Bill annualy</Text>
                </ToggleContainer>
            </BillingBox>
            <StyledPlans>
                <BillingPlan plan={selectedBillingPlan} saveAmount={saveAmount > 0 && selectedBillingPlan?.interval !== 'month' ? saveAmount : undefined} />
            </StyledPlans>
            <TotalsContainer>
                <StyledTotalsContainer $borderBottom>
                    <StyledTotalsLine>
                        <Text $black $bold>{`LeadDelta ${selectedBillingPlan?.name}`}</Text>
                        <Text $black $bold>{selectedBillingPlan?.currency === 'usd' ? '$' : '€'}{(selectedBillingPlan?.price / 100).toFixed(2)}</Text>
                    </StyledTotalsLine>
                    <StyledTotalsLine>
                        <Text $black $paragraph3>{`Billed ${selectedBillingPlan?.interval === 'month' ? 'monthly' : 'annualy'}`}</Text>
                    </StyledTotalsLine>
                </StyledTotalsContainer>
                <StyledTotalsContainer>
                    <StyledTotalsLine>
                        <Text $black $bold>Subtotal</Text>
                        <Text $black $bold>{selectedBillingPlan?.currency === 'usd' ? '$' : '€'}{(selectedBillingPlan?.price / 100).toFixed(2)}</Text>
                    </StyledTotalsLine>
                </StyledTotalsContainer>
                {discountForm && <StyledTotalsContainer>
                    <DiscountFormBox discountAmount={discountAmount} currency={selectedBillingPlan?.currency === 'usd' ? '$' : '€'} />
                </StyledTotalsContainer>}
                {validSubscription && workspace && !trial && subscription?.quantity &&
                    <StyledTotalsLine>
                        <Text $black $bold>Licences purchased</Text>
                        <Text $black $bold>{subscription?.quantity}</Text>
                    </StyledTotalsLine>
                }
                {workspace?.seatsUsed &&
                    <StyledTotalsLine>
                        <Text $black $bold>Seats used</Text>
                        <Text $black $bold>{workspace?.seatsUsed}</Text>
                    </StyledTotalsLine>
                }
                {quantityFormState && context === "workspace" && <StyledTotalsContainer $flexDirection='row'>
                    <FormControl $width='120px' $orientation="column" error={quantityFormState?.errors?.[0]}>
                        <InputNumber prefilledValue={+quantityFormState?.property} onChangeHandler={value => setLicenceQuantityHandler(value)} minValue={quantityFormState?.limits?.min} />
                    </FormControl>
                    <StyledText><Text $bold $black>{workspace?.seatsUsed ? "Additional " : ""}Licence Quantity</Text></StyledText>
                </StyledTotalsContainer >}
                <StyledTotalsContainer $borderTop>
                    <StyledTotalsLine>
                        <Text $black $heading4>Total:</Text>
                        <Text $black $heading4>{selectedBillingPlan?.currency === 'usd' ? '$' : '€'}{(upcomingPayment && licenceQuantity ? upcomingPayment.amountToPay / 100 : totalAmount).toFixed(2)}</Text>
                    </StyledTotalsLine>
                </StyledTotalsContainer>
                {upcomingPayment?.amountRemaining > 0 && <StyledTotalsContainer $borderTop>
                    <StyledTotalsLine>
                        <Text $black $heading4>Stripe credits:</Text>
                        <Text $black $heading4>{selectedBillingPlan?.currency === 'usd' ? '$' : '€'}{(upcomingPayment.amountRemaining / 100).toFixed(2)}</Text>
                    </StyledTotalsLine>
                </StyledTotalsContainer>}
            </TotalsContainer>
        </Container >
    )
}
const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`
const StyledPlans = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`
const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`
const TotalsContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const StyledTotalsContainer = styled.div<IStyledTotalsProps>`
    display: flex;
    flex-direction: ${({ $flexDirection }) => $flexDirection ? $flexDirection : 'column'};

    gap: 6px;
    padding: 16px 0px;
    ${({ $borderTop }) => $borderTop && `border-top: 1px solid ${styles.colors.disabledState}`}
    ${({ $borderBottom }) => $borderBottom && `border-bottom: 1px solid ${styles.colors.disabledState}`}
`
const StyledTotalsLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

`
const StyledText = styled.div`
    margin-top: 11px;
`

export default BillingPlans