import { ActionType, TBillingAction, TBillingState } from "../../interfaces/store/billingStore";
import { makeStore } from "../makeStore";


const initialState: TBillingState = {
    billingPlans: [],
    selectedBillingPlan: undefined,
    setupIntent: undefined,
    appliedCoupon: undefined,
    quantityFormState: undefined,
    licenceQuantity: undefined,
    invoices: undefined,
    upcomingPayment: undefined
}

const reducer = (state: TBillingState, action: TBillingAction): TBillingState => {
    switch (action.type) {
        case ActionType.SET_BILLING_PLANS: return { ...state, billingPlans: action.payload }
        case ActionType.SET_SELECTED_PLAN: return { ...state, selectedBillingPlan: action.payload }
        case ActionType.SET_SETUP_INTENT: return { ...state, setupIntent: action.payload }
        case ActionType.SET_APPLIED_COUPON: return { ...state, appliedCoupon: action.payload }
        case ActionType.SET_QUANTITY_FORM_STATE: return { ...state, quantityFormState: action.payload }
        case ActionType.SET_LICENCE_QUANTITY: return { ...state, licenceQuantity: action.payload }
        case ActionType.SET_INVOICES: return { ...state, invoices: action.payload }
        case ActionType.SET_UPCOMING_PAYMENT: return { ...state, upcomingPayment: action.payload }
        case ActionType.RESET_STORE: return { ...initialState }
        default: return state
    }
}

const { StoreProvider: BillingStoreProvider, useStoreContext: useBillingStore } = makeStore(reducer, initialState)

export { BillingStoreProvider, useBillingStore }