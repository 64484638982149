import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import Logo from './Logo'
import { styles } from './../styles/themes/style';
import ProfilePhotoDropdown from './dropdown/header/ProfilePhotoDropdown';
import { useNavigate } from 'react-router-dom';

interface IHeaderProps { }

const Header: FunctionComponent<IHeaderProps> = () => {
    const navigate = useNavigate();

    return (
        <Container>
            <Logo
                onClick={() => navigate('/')}
            />
            <ProfilePhotoDropdown />
        </Container>
    )
}

const Container = styled.div`
    height: ${styles.lengths.navbarHeight};
    width: 100%;
    padding: 0 30px;
    border-bottom: 2px solid ${({ theme: { quinaryColor } }) => quinaryColor};
    background-color: ${({ theme: { secondaryColor } }) => secondaryColor};
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export default Header