import React, { FunctionComponent, ReactNode, useEffect, useState } from "react"
import moment from 'moment';
import styled from "styled-components"
import { styles } from "../../../styles/themes/style";
import { useBillingStore } from "../../../stores/billing/useBillingStore";
import { useBillingRequest } from "../../../services/apis/billing/useBillingRequest";
import { useBillingActions } from "../../../stores/billing/useBillingActions";

import Table from "../../../components/table/Table"
import TableCell from "../../../components/table/TableCell"
import TableData from "../../../components/table/TableData"
import TableHeader from "../../../components/table/TableHeader"
import TableRow from "../../../components/table/TableRow"
import Text from "../../../components/Text"
import Tag from "../../../components/Tag";
import Button from "../../../components/Button";
import { InvoceTableDataPreloader, InvoceTableRowPreloader } from "../preloaders/InvoiceTableTadaPreloader";
import { useAppStore } from "../../../stores/application/useAppStore";
import { useBillingHandlers } from "../../../services/actions/billing/useBillingHandlers";


interface IInvoicesTableProps { }

const InvoicesTable: FunctionComponent<IInvoicesTableProps> = () => {
    const { store: { invoices } } = useBillingStore()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [lastInvoiceId, setLastInvoiceId] = useState<string>('')
    const { store: { requestLoading } } = useAppStore()
    const { getInvoicesHandler } = useBillingHandlers()

    const handleScroll = async (e: React.UIEvent<HTMLElement>) => {
        if ((e.currentTarget.scrollTop + e.currentTarget.offsetHeight) === e.currentTarget.scrollHeight) {
            if (lastInvoiceId !== invoices?.[invoices.length - 1]?.id) {
                setLastInvoiceId(invoices[invoices.length - 1].id)
            }

        }
    }
    useEffect(() => {
        requestLoading?.type === 'invoices' && setIsLoading(requestLoading.loading)
    }, [requestLoading])

    useEffect(() => {
        getInvoicesHandler(lastInvoiceId)
    }, [lastInvoiceId])

    return (<Table>
        <TableRow $header>
            <TableHeader $width="40%">Invoice</TableHeader>
            <TableHeader $width="19%">Status</TableHeader>
            <TableHeader $width="20%">Amount</TableHeader>
            <TableHeader $width="21%">Date</TableHeader>
        </TableRow>
        {invoices ?
            <TableData onScrollHandler={(e) => !isLoading && handleScroll(e)} $maxHeight="249px">
                {invoices && invoices.map((invoice: any) => (
                    <TableRow $hiddenCell='5' key={invoice.id}>
                        <TableCell $width="40%"><Text $bold $black>{invoice.productName && `${invoice.productName} - `}{moment.unix(invoice.createdAt).format('MMM YYYY')}</Text></TableCell>
                        <TableCell $width="19%">{invoice.status === 'paid' && <StyledTag><Tag title={'Paid'} $borderColor={styles.colors.green600} $color={styles.colors.green200} SVGtype={'checkmark'} /></StyledTag>}</TableCell>
                        <TableCell $width="20%"><Text $lighter>{invoice.currency === 'usd' ? '$' : '€'}{(invoice.amountDue / 100).toFixed(2)}</Text></TableCell>
                        <TableCell $width="21%">{moment.unix(invoice.createdAt).format('MMM D, YYYY')}</TableCell>
                        <TableCell $width="auto"><Button $type="blue" onClickHandler={() => window.open(invoice.downloadUrl, '_blank')}>Download</Button></TableCell>
                    </TableRow>
                ))}
                {isLoading && <InvoceTableRowPreloader />}
            </TableData>
            : <InvoceTableDataPreloader />
        }
    </Table>)
}
const StyledTag = styled.div`
    background-color: ${styles.colors.green200};

    & svg {
        width: 20px;
        height: 16px
    }
`
export default InvoicesTable