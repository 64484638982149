import React from 'react';
import styled from 'styled-components';

import Checks from './pages/Checks/Checks';

const Home = React.forwardRef((props: any, ref: any) => {
    const {
        ...other
    } = props;

    return (
        <Root
            ref={ref}

            {...other}
        >
            <Checks />
        </Root>
    );
});

const Root = styled.div`
    height: 100%;
`;

export default Home; 
