import { FunctionComponent, useEffect, useState } from 'react'

import { useAppHandlers } from '../../../services/actions/app/useAppHandlers'
import { useBillingHandlers } from '../../../services/actions/billing/useBillingHandlers'
import { useAuthStore } from '../../../stores/auth/useAuthStore'
import { useBillingStore } from '../../../stores/billing/useBillingStore'
import { useAppStore } from '../../../stores/application/useAppStore'

import styled from 'styled-components'
import Button from '../../../components/Button'
import Text from '../../../components/Text'
import Tag from '../../../components/Tag'
import { styles } from '../../../styles/themes/style'
import InvoicesTable from '../components/InvoicesTable'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { IBillingPlan } from '../../../interfaces/models/billingPlan'
import PaymentInformationPreloader from '../preloaders/PaymentInformationPreloader'
import { IPriceModel } from '../../../interfaces/models/price'

interface ISubscriptionSectionProps { }

const SubscriptionSection: FunctionComponent<ISubscriptionSectionProps> = () => {
    const { store: { user, subscription, workspace, validSubscription } } = useAuthStore()
    const { store: { billingPlans } } = useBillingStore()
    const { store: { requestLoading } } = useAppStore()
    const { setPauseFlow, setCancelFlow } = useAppHandlers()
    const navigate = useNavigate()
    const { getCurrentPayingPriceHandler, onResumeSubscriptionHandler } = useBillingHandlers()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [priceModel, setPriceModel] = useState<IPriceModel | undefined>()
    const [planPrice, setPlanPrice] = useState<IBillingPlan | undefined>()

    useEffect(() => {
        (async function () {
            if (subscription) {
                const result = await getCurrentPayingPriceHandler(subscription.priceId)
                result && setPriceModel(result)
            }
        })();
    }, [subscription])

    useEffect(() => {
        const price = subscription ? billingPlans?.find((billingPlan: IBillingPlan) => billingPlan.priceId === subscription.priceId) : undefined
        setPlanPrice(price ?? undefined)
    }, [billingPlans])

    useEffect(() => {
        requestLoading?.type === 'currentPrice' && setIsLoading(requestLoading.loading)
    }, [requestLoading])

    return (
        <Container>
            {!isLoading && subscription ? <PaymentInformation $borderColor={subscription?.pausedUntil || !subscription?.isValid}>
                <PaymentInformationLeft>
                    <Info>
                        <Text $heading5 $bold $black>LeadDelta <Text $heading5 $bold $black $capitalize>{priceModel?.recurring?.interval ?? subscription?.interval}ly</Text></Text>
                        <Tag
                            title={(!subscription?.pausedUntil && !subscription?.cancelDate || subscription?.cancelDate && subscription?.isValid && subscription?.cancelDate < moment().add(process.env.REACT_APP_SUBSCRIPTION_EXPIRING_DAYS ?? '5', 'days').unix()) ? `Active` : subscription?.pausedUntil ? `Paused` : subscription?.cancelDate > moment().add(process.env.REACT_APP_SUBSCRIPTION_EXPIRING_DAYS ?? '5', 'days').unix() ? 'Expiring soon' : `Deactivated`}
                            $borderColor={(!subscription?.pausedUntil && !subscription?.cancelDate || subscription?.cancelDate && subscription?.isValid && subscription?.cancelDate < moment().add(process.env.REACT_APP_SUBSCRIPTION_EXPIRING_DAYS ?? '5', 'days').unix()) ? styles.colors.green600 : subscription?.pausedUntil ? styles.colors.red600 : subscription?.cancelDate > moment().add(process.env.REACT_APP_SUBSCRIPTION_EXPIRING_DAYS ?? '5', 'days').unix() ? styles.colors.orange600 : styles.colors.black400}
                            $color={(!subscription?.pausedUntil && !subscription?.cancelDate || subscription?.cancelDate && subscription?.isValid && subscription?.cancelDate < moment().add(process.env.REACT_APP_SUBSCRIPTION_EXPIRING_DAYS ?? '5', 'days').unix()) ? styles.colors.green200 : subscription?.pausedUntil ? styles.colors.red200 : subscription?.cancelDate > moment().add(process.env.REACT_APP_SUBSCRIPTION_EXPIRING_DAYS ?? '5', 'days').unix() ? styles.colors.orange200 : styles.colors.black200}
                        />
                    </Info>
                    <Text $black>{subscription?.currency === 'usd' ? '$' : '€'}{((planPrice?.price ?? priceModel?.unit_amount ?? 0) / 100).toFixed(2)} per {priceModel?.recurring?.interval ?? subscription?.interval} {workspace && `per licence (${subscription.quantity} Licence${subscription.quantity > 1 ? 's' : ''})`}</Text>
                    <Text $lighter>{
                        (!subscription?.pausedUntil && !subscription?.cancelDate) ? `Next payment due on ${moment.unix(subscription?.periodEnd).format('MMM D, YYYY')}` :
                            subscription?.pausedUntil ? `Your subscription is paused until ${moment.unix(subscription?.pausedUntil).format('MMM D, YYYY')}` :
                                subscription?.cancelDate && subscription?.isValid ? `Your subscription is expiring on ${moment.unix(subscription?.cancelDate).format('MMM D, YYYY')}` : `Your last payment was on ${moment.unix(subscription?.cancelDate).format('MMM D, YYYY')}`}</Text>
                </PaymentInformationLeft>
                <PaymentInformationRight>
                    {(validSubscription || subscription?.pausedUntil || subscription?.cancelDate) && <Button $type='grey' onClickHandler={() => navigate("/billing/paymentMethods")}>{subscription?.paymentMethods?.length === 0 ? 'Add payment details' : 'Edit payment details'}</Button>}
                    {validSubscription && !subscription?.pausedUntil && !subscription?.cancelDate && <Button disabled={subscription?.paymentMethods?.length === 0 || user?.stripePaymentMethods?.length === 0 } $type='blue' $mediumButton onClickHandler={() => navigate("/billing/updateSubscription")}>Manage</Button>}
                    {((subscription?.cancelDate && subscription?.isValid) || subscription?.pausedUntil || !subscription?.isValid) && <Button $type='blue' $mediumButton onClickHandler={() => !subscription?.isValid && subscription?.cancelDate ? navigate("/billing/updateSubscription") : onResumeSubscriptionHandler((!subscription?.isValid && subscription?.pausedUntil) ? { resumePause: true } : { resumeCancel: true })}>Reactivate</Button>}
                </PaymentInformationRight>
            </PaymentInformation> : <PaymentInformationPreloader />}
            {/* {!priceModel && !planPrice && !isLoading && <PaymentInformation>
                <Text $bold $red>We are havin trouble getting your payment model. Please refresh the page!</Text>
            </PaymentInformation>} */}
            {user && <BillingHistory>
                <Text $heading5 $bold $black>Billing History</Text>
                <InvoicesTable />
            </BillingHistory>}
            {(user && !subscription?.pausedUntil && !subscription?.cancelDate) && <CancelSubscription>
                <Text $heading5 $bold $black>Cancel Your Subscription</Text>
                <Text $black>Time to take a break? We totally understand.</Text>
                <Button $type='red' $mediumButton onClickHandler={setPauseFlow}>Cancel</Button>
            </CancelSubscription>}
        </Container>
    )
}
const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
`
const PaymentInformation = styled.div<{ $borderColor?: boolean }>`
    width: 730px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 10px;
    background: ${(props: any) => props.theme.secondaryColor};
    border: 2px solid ${({ $borderColor, theme }: any) => $borderColor ? 'none' : theme.primary600};
    border-radius: 14px;
`
const PaymentInformationLeft = styled.div`
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly
`
const Info = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`
const PaymentInformationRight = styled.div`
    width: 250px;
    display: flex;
    justify-content: space-around;
`
const BillingHistory = styled.section`
    margin: 25px 0 25px 0; 
`
const CancelSubscription = styled.section`
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: auto;
`

export default SubscriptionSection