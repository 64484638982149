import { FunctionComponent } from 'react'
import { useAppHandlers } from '../../../../../services/actions/app/useAppHandlers';
import CustomModal from "../../../CustomModal";


const SubscriptionCanceledSection: FunctionComponent = () => {

    return (
        <CustomModal
            descriptionTextBold='Your subscription has been canceled.'
            logo
            $center
            divider
            closeButton
            confirmButton={{
                text: 'Got it',
                mediumButton: true,
                onClickHandler: () => window.open(`/billing`, '_self')
            }}
        >
        </CustomModal>
    )

}

export default SubscriptionCanceledSection