import styled from 'styled-components';

import { ReactComponent as LogoSymbol } from '../assets/img/logo-symbol.svg';
import { ReactComponent as LogoEntire } from '../assets/img/logo.svg';

import useMediaQuery from './useMediaQuery';

const Logo = (props: any) => {
    // in case we need to use theme 
    // const theme: DefaultTheme = useTheme()  
    const isMobile = useMediaQuery('(max-width: 1200px)');

    return (
        <Root
            {...props}
        >
            {isMobile ? <LogoSymbol /> : <LogoEntire />}
        </Root>
    )
}

const Root = styled.span` 
    display: inline-flex; 
    cursor: pointer; 
    user-select: none; 

    & > svg {
        height: 32px;
        width: auto; 
    }
`

export default Logo