import { FunctionComponent, ReactNode } from "react"
import { styles } from "../../styles/themes/style";
import styled from "styled-components"

interface IStyledTableRowProps {
    $header?: boolean,
    $hiddenCell?: string
}

export interface ITableRowProps extends IStyledTableRowProps {
    children?: ReactNode
}

const TableRow: FunctionComponent<ITableRowProps> = ({ children, ...props }) => {
    return (<StyledTableRow {...props}>
        {children}
    </StyledTableRow>)
}
const StyledTableRow = styled.div<IStyledTableRowProps>`
    display: flex;
    ${({ $header, theme: { senaryColor } }) => !$header && `
        min-height: 60px;
        align-items: center;
        background-color: ${senaryColor};
        &:first-child {
            border-radius: 14px 14px 0px 0px;
        }
        &:last-child {
            border-radius: 0px 0px 14px 14px;
        }
    `}
    ${({ $hiddenCell }) => $hiddenCell && `
        & div:nth-child(${$hiddenCell}) { display: none };
        &:hover div:nth-child(${$hiddenCell}) { display: block };
    `}
`
export default TableRow