import React from 'react';
import axios from 'axios';
import styled from 'styled-components';

import {
    AutomationsManager,
    RolloutConnectProvider,
    defaultTheme,
} from "@rollouthq/connect-react";

import { useAuthStore } from '../../stores/auth/useAuthStore';

const Rollout = React.forwardRef((props, ref) => {
    const [token, setToken] = React.useState();
    const { store: { authData: { accessToken, refreshToken } } } = useAuthStore()

    const getToken = React.useCallback(async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/auth/rollout/token`, {
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                Authorization: accessToken,
                ...refreshToken && { 'refresh-token': refreshToken }
            }
        });

        const value = data.data;

        setToken(value);
    }, []);

    const init = React.useCallback(async () => {
        await getToken();
    }, []);

    React.useEffect(() => {
        init();
    }, []);

    console.log('Rollout', token);

    return (
        <Root>
            {token && (
                <RolloutConnectProvider token={token}>
                    <AutomationsManager />
                </RolloutConnectProvider>
            )}
        </Root>
    );
});

defaultTheme();

const Root = styled.div`
    display: flex;
    flex-direction: column; 
    padding: 40px;
`;

export default Rollout; 
