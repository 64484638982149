import React, { FunctionComponent, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useAuthStore } from '../../../stores/auth/useAuthStore'
import { useBillingActions } from '../../../stores/billing/useBillingActions'
import { useBillingStore } from '../../../stores/billing/useBillingStore'
import BillingBox from '../components/BillingBox'
import BillingPlans from '../components/BillingPlans'
import SavedPaymentForm from '../components/SavedPaymentForm'

interface IUpdateSubscriptionSectionProps {
    payNow?: boolean
}

const UpdateSubscriptionSection: FunctionComponent<IUpdateSubscriptionSectionProps> = ({ payNow = true }) => {

    const { store: { setupIntent, selectedBillingPlan } } = useBillingStore()
    const { store: { subscription, workspace, validSubscription, trial } } = useAuthStore()
    const { setLicenceQuantity } = useBillingActions()
    const navigate = useNavigate()

    useEffect(() => {
        !subscription || !validSubscription || trial && navigate("/billing")
        let additionalLicence = workspace && selectedBillingPlan && subscription && selectedBillingPlan.priceId === subscription.priceId ? 1 : 0
        let calculateLicenceQuantity = subscription?.quantity && workspace?.seatsUsed && subscription?.quantity > workspace?.seatsUsed ?
            (subscription?.quantity ? subscription?.quantity + additionalLicence : 1) :
            (workspace?.seatsUsed ? workspace?.seatsUsed + additionalLicence : 1)
        setLicenceQuantity(subscription?.isValid ? calculateLicenceQuantity : 1)
    }, [subscription, workspace, selectedBillingPlan, validSubscription, trial])

    //todo: add email
    return (
        <Container>
            <StyledSection><BillingPlans updatePlan /></StyledSection>
            <StyledSection>
                {!setupIntent ?
                    <BillingBox>
                        Problem loading Stripe, please try again later
                    </BillingBox> :
                    <SavedPaymentForm />}
            </StyledSection>
        </Container>
    )
}
const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
`
const StyledSection = styled.div`
    width: 350px;
    min-width: fit-content;
`

export default UpdateSubscriptionSection