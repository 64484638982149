import React, { FunctionComponent, useEffect } from 'react'
import styled from 'styled-components'
import Table from '../../../components/table/Table'
import TableCell from '../../../components/table/TableCell'
import TableData from '../../../components/table/TableData'
import TableHeader from '../../../components/table/TableHeader'
import TableRow from '../../../components/table/TableRow'
import Tag from '../../../components/Tag'
import Text from '../../../components/Text'
import { IStripePaymentMethod } from '../../../interfaces/models/user'
import { useAuthStore } from '../../../stores/auth/useAuthStore'
import { styles } from "../../../styles/themes/style";
import sepaImage from '../../../assets/img/billing/sepa.png'
import cardImage from '../../../assets/img/billing/card.png'

interface IPaymentMethodsProps { }

const PaymentMethods: FunctionComponent<IPaymentMethodsProps> = () => {

    const { store: { subscription, user } } = useAuthStore()//todo check sepa representation

    //constants
    const cardImg = <img
        src={cardImage}
        alt=''
    />
    const sepaImg = <img
        src={sepaImage}
        alt=''
    />

    return (
        <Container>
            <Text $black $heading5>Payment Methods</Text>
            {subscription?.paymentMethods?.length > 0 ?
                <Table>
                    <TableRow $header>
                        <TableHeader $width="110px">Type</TableHeader>
                        <TableHeader $width="200px">Details</TableHeader>
                        <TableHeader $width="auto">Default</TableHeader>
                    </TableRow>
                    <TableData $maxHeight="540px">
                        {subscription?.paymentMethods?.length > 0 && subscription?.paymentMethods?.map((paymentMethod: IStripePaymentMethod) => (
                            <TableRow key={paymentMethod.id}>
                                <TableCell $width="110px"><StyledImage>{paymentMethod.type === 'sepa_debit' ? sepaImg : cardImg}</StyledImage> <Text $capitalize $bold $black>{paymentMethod.type}</Text> </TableCell>
                                <TableCell $width="200px"> <Text $black><Text $black $capitalize>{paymentMethod.brand === 'sepa_debit' ? 'sepa' : paymentMethod.brand}</Text> ending with {paymentMethod.last4}</Text></TableCell>
                                <TableCell $width="auto">{paymentMethod.isDefault && <StyledTag><Tag title={'Default'} $borderColor={styles.colors.green600} $color={styles.colors.green200} SVGtype={'checkmark'} /></StyledTag>}</TableCell>
                            </TableRow>
                        ))}
                        {subscription?.paymentMethods?.length === 0 && user?.stripePaymentMethods && user?.stripePaymentMethods.map((paymentMethod: IStripePaymentMethod) => (
                            <TableRow key={paymentMethod.id}>
                                <TableCell $width="110px"><StyledImage>{paymentMethod.type === 'sepa_debit' ? sepaImg : cardImg}</StyledImage> <Text $capitalize $bold $black>{paymentMethod.type}</Text> </TableCell>
                                <TableCell $width="200px"> <Text $black><Text $black $capitalize>{paymentMethod.brand === 'sepa_debit' ? 'sepa' : paymentMethod.brand}</Text> ending with {paymentMethod.last4}</Text></TableCell>
                                <TableCell $width="auto">{paymentMethod.isDefault && <StyledTag><Tag title={'Default'} $borderColor={styles.colors.green600} $color={styles.colors.green200} SVGtype={'checkmark'} /></StyledTag>}</TableCell>
                            </TableRow>
                        ))}
                    </TableData>
                </Table> : <Text $bold $heading5 $lighter>You don`t have any payment methods.</Text>
            }
        </Container>
    )
}
const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`
const StyledTag = styled.div`
    background-color: ${styles.colors.green200};

    & svg {
        width: 20px;
        height: 16px
    }
`
const StyledImage = styled.div`
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
`


export default PaymentMethods