import { FunctionComponent, ReactNode } from "react"
import styled from "styled-components"

interface IStyledTableDataProps {
    $maxHeight?: string,
    onScrollHandler?: (e: any) => void
}

export interface ITableDataProps extends IStyledTableDataProps {
    children?: ReactNode
}

const TableData: FunctionComponent<ITableDataProps> = ({ children, onScrollHandler, ...props }) => {

    return (<StyledTableData onScroll={onScrollHandler} {...props} >
        {children}
    </StyledTableData>)
}
const StyledTableData = styled.div<IStyledTableDataProps>`
    ${({ $maxHeight }) => $maxHeight && `max-height: ${$maxHeight};`}
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 2px;
`
export default TableData