
import { ActionType, IRequestLoading, IModal, IToast } from "../../interfaces/store/appStore"
import { useAppStore } from "./useAppStore"

export const useAppActions = () => {
    const { dispatch } = useAppStore()
    const setModal = (payload?: IModal) => {
        return dispatch({
            type: ActionType.SET_MODAL,
            payload
        })
    }

    const setToast = (payload?: IToast) => {
        return dispatch({
            type: ActionType.SET_TOAST,
            payload
        })
    }

    const setRequestLoading = (payload: IRequestLoading) => {
        return dispatch({
            type: ActionType.SET_REQUEST_LOADING,
            payload
        })
    }

    const setSpinner = (payload: boolean) => {
        return dispatch({
            type: ActionType.SET_SPINNER,
            payload
        })
    }

    return {
        setModal,
        setToast,
        setRequestLoading,
        setSpinner
    }
}


