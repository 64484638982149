import { FunctionComponent, ReactNode } from "react"
import { styles } from "../../styles/themes/style";
import styled from "styled-components"

interface IStyledTableCellProps {
    $width?: string,
    $header?: boolean
}

export interface ITableCellProps extends IStyledTableCellProps {
    children?: ReactNode
}

const TableCell: FunctionComponent<ITableCellProps> = ({ children, ...props }) => {
    return (<StyledTableCell {...props}>
        {children}
    </StyledTableCell>)
}
const StyledTableCell = styled.div<IStyledTableCellProps>`
    width: ${({ $width }) => $width ?? '100%'};
    ${({ $width }) => $width === 'auto' && `
    flex-grow: 1;
    flex-basis: 0;
    `}
    padding: ${({ $header }) => $header ? '8px 16px' : '2px 16px'};
    ${({ $header, theme: { senaryColor } }) => !$header && `
        display: flex;
        align-items: center;
    `}
`
export default TableCell