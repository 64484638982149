import React, { FunctionComponent, useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom';
import ActionText from '../../components/ActionText'
import { styles } from './../../styles/themes/style';
import styled, { DefaultTheme, useTheme } from 'styled-components'
import Text from '../../components/Text';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../stores/auth/useAuthStore';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useBillingHandlers } from '../../services/actions/billing/useBillingHandlers';
import { useBillingStore } from '../../stores/billing/useBillingStore';
import { useAppStore } from '../../stores/application/useAppStore';
import { useAppHandlers } from '../../services/actions/app/useAppHandlers';
import { analytic, getPageTitle, getPageUrl } from '../../utils';

interface IBillingPageProps { }

const BillingPage: FunctionComponent<IBillingPageProps> = () => {
    const theme: DefaultTheme = useTheme()
    const navigate = useNavigate()
    const [stripePromise] = useState(() => loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY}`))
    const [stripeOptions, setStripeOptions] = useState<any>()
    const [billingPlansLoading, setBillingPlansLoading] = useState<boolean>(true)
    const [setupIntentLoading, setSetupIntentLoading] = useState<boolean>(true)
    const { getBillingPlansHandler, getSetupIntentHandler } = useBillingHandlers()
    const { store: { setupIntent, selectedBillingPlan } } = useBillingStore()
    const { store: { requestLoading } } = useAppStore()
    const { store: { lifetime, subscription, workspaceOwner, context } } = useAuthStore()
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        if (lifetime && context !== 'workspace' && location.pathname !== '/info') navigate('/info')
        if (!workspaceOwner && context === 'workspace' && location.pathname !== '/info') navigate('/info')
    }, [location, lifetime, workspaceOwner, context]);

    const [redirectClientSecret, setRedirectClientSecret] = useState<string | undefined>()

    useEffect(() => {
        getBillingPlansHandler()
        const setupIntentClientSecret = searchParams.get('setup_intent_client_secret') ?? undefined
        setRedirectClientSecret(setupIntentClientSecret)
        if ((!lifetime || lifetime && context === 'workspace') && !setupIntentClientSecret) {
            getSetupIntentHandler()
        }
    }, [lifetime, searchParams])

    useEffect(() => {
        if (setupIntent || redirectClientSecret) {
            setStripeOptions({
                clientSecret: setupIntent?.client_secret ?? redirectClientSecret,
                appearance
            })
            setSetupIntentLoading(false)
        }
    }, [setupIntent, redirectClientSecret])

    useEffect(() => {
        if (setupIntent) {
            // analytic 
            analytic({
                event: 'begin_checkout',
                pageUrl: getPageUrl(),
                pageTitle: getPageTitle(),
                currency: selectedBillingPlan?.currency,
                value: selectedBillingPlan?.price,
                items: [
                    {
                        item_id: 'LD-paid',
                        item_name: 'LD paid plan'
                    }
                ]
            });
        }
    }, [setupIntent, selectedBillingPlan]);

    useEffect(() => {
        requestLoading?.type === 'billingPlans' && setBillingPlansLoading(requestLoading.loading)
        requestLoading?.type === 'setupIntent' && setSetupIntentLoading(requestLoading.loading)
    }, [requestLoading])

    const appearance = {
        theme: 'none',
        variables: {
            colorPrimary: styles.colors.primary600,
            colorBackground: theme.secondaryColor,
            colorText: theme.primaryColor,
            colorDanger: styles.colors.red600,
            colorSuccess: styles.colors.green600,
            fontFamily: 'Avenir, system-ui, sans-serif',
            spacingUnit: '3px',
            borderRadius: '4px'
        },
        rules: {
            '.Input': {
                border: `2px solid ${theme.quinaryColor}`,
                outline: 'none',
                padding: '7px 10px'
            },
            '.Input:hover': {
                border: `2px solid ${styles.colors.primary600}`,
            },
            '.Input:focus': {
                border: `2px solid ${styles.colors.primary600}`,
            },
            '.Label': {
                fontSize: '11px',
                lineHeight: '16px',
                textTransform: 'uppercase'
            },
            '.Error': {
                fontSize: '11px',
                lineHeight: '16px'
            }
            // See all supported class names and selector syntax https://stripe.com/docs/elements/appearance-api?platform=web#variables
        }
    }

    if (billingPlansLoading) {
        return (
            <div>Billing Loading</div>
        )
    }

    return (
        <Container>
            <Text $black $heading1>Billing</Text>
            <Text $black>Need any help? Contact <ActionText onClickHandler={() => window.open("mailto:support@leaddelta.com")}>support@leaddelta.com</ActionText> or <ActionText onClickHandler={() => (window as any).$crisp.push(['do', 'chat:open'])}>chat now</ActionText>.</Text>
            {!setupIntentLoading && stripeOptions && <Elements stripe={stripePromise} options={stripeOptions} >
                <Outlet />
            </Elements>}
        </Container>
    )
}
const Container = styled.div`
    width: 100%;
    padding: 20px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y:auto;
`

export default BillingPage