import { FunctionComponent, useEffect, useState } from "react";
import { styles } from "../styles/themes/style";

import styled from "styled-components";
import SvgFragment from "../fragments/SvgFragment";
import Input from "./Input";

interface IStyledInputProps {
    $error?: boolean,
    $disabled?: boolean,//todo: check disabled in input
}

export interface ICounterProps extends IStyledInputProps {
    type?: string,
    placeholder?: string,
    prefilledValue?: number,
    minValue?: number,
    onChangeHandler: (e: any) => void
}

const InputNumber: FunctionComponent<ICounterProps> = ({ $disabled, prefilledValue, minValue, onChangeHandler, ...props }) => {
    const [$value, setValue] = useState<number>(prefilledValue ?? 0)
    const [$minValue, setMinValue] = useState<number | undefined>(minValue)
    useEffect(() => {
        setValue(prefilledValue ?? 0)
    }, [prefilledValue])
    useEffect(() => {
        setMinValue(minValue)
    }, [minValue])
    const inputChangedHandler = (value: number) => {
        setValue(value)
        onChangeHandler(value)
    }
    return (
        <Container>
            <InputWrapper>
                <Input
                    type='number'
                    $noBorder
                    prefilledValue={$value?.toString()}
                    onChangeHandler={value => inputChangedHandler(+value)}
                    $disabled
                    {...props}
                />
                <Divider />
                <StyledCounter onClick={() => ($minValue === undefined || $value - 1 >= $minValue) && inputChangedHandler($value - 1)}>
                    <SvgFragment type="minus" />
                </StyledCounter>
                <Divider />
                <StyledCounter onClick={() => inputChangedHandler($value + 1)}>
                    <SvgFragment type="plus" />
                </StyledCounter>
            </InputWrapper>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
const InputWrapper = styled.div`
    width: 125px;
    height: 35px;
    display: flex;
    background: ${(props: any) => props.theme.secondaryColor};
    border: 2px solid ${({ theme: { quinaryColor } }) => `${quinaryColor}`};
    border-radius: 4px;

    &:hover { border: 2px solid ${styles.colors.primary600}; }

    & input  {background-color: transparent; }
`
const StyledCounter = styled.button`
    width: 30px;
    height: 31px;
    display: flex;
    background: ${(props: any) => props.theme.secondaryColor};
    border: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    ${({ disabled }) => disabled ? `line { stroke: ${styles.colors.disabledState}; }` : `&:hover line { stroke: ${styles.colors.primary400}; }`}

    &:hover { background-color: ${styles.colors.primary100} }
`
const Divider = styled.div`
    width: 2px;
    height: 31px;
    background: ${({ theme: { quinaryColor } }) => `${quinaryColor}`};
`
export default InputNumber