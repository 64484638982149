import { FunctionComponent } from 'react'
import { useAppStore } from '../../../stores/application/useAppStore'

import CustomModal from '../CustomModal'

const ConfirmationModal: FunctionComponent = () => {
    const { store: { modal } } = useAppStore()

    return (
        <CustomModal
            headingText={modal?.headingText}
            descriptionText={modal?.descriptionText}
            descriptionTextBold={modal?.descriptionTextBold}
            $center
            cancelButton={
                modal?.onCancelHandler ?
                    {
                        text: 'No',
                        mediumButton: true,
                        onClickHandler: modal?.onCancelHandler
                    } : undefined
            }
            confirmButton={
                modal?.onConfirmHandler ?
                    {
                        text: 'Yes',
                        mediumButton: true,
                        onClickHandler: modal?.onConfirmHandler

                    } : undefined
            }
        />
    )
}

export default ConfirmationModal