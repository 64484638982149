import React, { FunctionComponent, ReactNode } from "react"
import styled from "styled-components"
import { styles } from "../../styles/themes/style";
import { Dropdown, DropdownButton } from 'react-bootstrap'
import Tooltip from "../../fragments/Tooltip";
import SvgFragment from "../../fragments/SvgFragment";
import ProfilePhoto from "../ProfilePhoto";

interface IStyledDropdownProps {
    $hideToggle?: boolean
}

interface IDropdownProps extends IStyledDropdownProps {
    title?: ReactNode,
    description?: string,
    SVGtype?: string,
    profileImage?: boolean,
    children?: ReactNode,
    imgSource?: string
}

const DropdownMenu: FunctionComponent<IDropdownProps> = ({ children, title, description, SVGtype, imgSource, $hideToggle, profileImage = false }) => {

    const dropdownButtonTitleElement = <>
        {SVGtype && <SvgFragment type={SVGtype} />}
        {title}
        {profileImage && <ProfilePhoto source={imgSource} />}
        {description && <Tooltip title={description} />}
    </>;

    return (
        <StyledDropownButton bsPrefix="actions-dropdown" title={dropdownButtonTitleElement} $hideToggle={$hideToggle}>
            {children}
        </StyledDropownButton>
    )
}

const StyledDropownButton = styled(DropdownButton) <IStyledDropdownProps>`
    background-color: ${({ theme: { secondaryColor } }) => secondaryColor};
    border: 1px solid ${({ theme: { secondaryColor } }) => secondaryColor};
    border-radius: 5px;
    transition: all .2s ease;
    button {
        font-family: NunitoSans;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        border-radius: 5px;
        border: none;
        width: auto;
        background: transparent;
        color:  ${styles.colors.primary600};
        &:hover { 
            .LDtooltip { display: block; }
            color: ${styles.colors.primary400};
            border: none;
            svg { fill: ${styles.colors.primary400} }
        }
    }
    .actions-dropdown + div{
        padding: 10px 0px;
        max-height: 350px;
        overflow-y: auto;
        min-width: 170px;
        z-index: 1005;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
        border: none;
        background-color: ${({ theme: { secondaryColor } }) => secondaryColor};
    }
    ${({ $hideToggle }) => $hideToggle && `.dropdown-toggle::after {
        display: none !important; 
    }`}
        
`

export default DropdownMenu