import React, { useEffect } from 'react';
import styled from 'styled-components';

import ImageShawn from '../../assets/img/signup/shawn.png';
import ImageQuadri from '../../assets/img/signup/quadri.png';
import ImagePia from '../../assets/img/signup/pia.png';
import ImageShane from '../../assets/img/signup/shane.png';
import ImageSigninSuccess from '../../assets/img/signup/signup-success.jpg';

import { ReactComponent as IconRating } from '../../assets/img/signup/5-stars.svg';

import Input from '../../components/Input';
import { isValid } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { useAppHandlers } from '../../services/actions/app/useAppHandlers';
import { useAuthActions } from '../../stores/auth/useAuthActions';
import { useAuthRequest } from '../../services/apis/auth/useAuthRequest';

const reviews = [
    {
        image: ImageShawn,
        quote: `Just implemented LeadDelta for the entire team at Autoklose. It allows us to better manage our LinkedIn network. Great app.`,
        by: `Shawn Finder, Co-founder of Autoklose`
    },
    {
        image: ImageQuadri,
        quote: `Amazing product. It helps me to grow and nurture my professional network. Often times I use it to communicate with my students and clients.`,
        by: `Quadri Oshibotu, Author and Founder of ProductHall`
    },
    {
        image: ImagePia,
        quote: `A tool that helps to better manage LinkedIn contacts with tags, notes, and more. For me in recruitment, in particular, it is a big help to structure my contacts. The tool is great!`,
        by: `Pia Teuner, HR Manager at Macaw`
    },
    {
        image: ImageShane,
        quote: `My teams have been using LeadDelta for +1yr to get a foot in the door. With workspaces, we can utilize low-hanging fruits and build a far more powerful internal network of supporters than our competitors.`,
        by: `Shane Starr, Head of Revenue at Fletch`
    }
];

const ForgotPassword = React.forwardRef((props: any, ref: any) => {
    const [step, setStep] = React.useState(1);
    const [values, setValues] = React.useState<any>({});
    const [valid, setValid] = React.useState(false);
    const [loaded, setLoaded] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState<any>(false);
    const { showSuccessToast, showErrorToast } = useAppHandlers();
    const { resetAuthStore } = useAuthActions()
    const { verificationCode, verificationCodeValidate, resetPassword } = useAuthRequest();
    const navigate = useNavigate();

    useEffect(() => {
        resetAuthStore()
    }, [])

    const SendResetInstructions = React.useCallback(async () => {
        setLoading(true);

        try {
            if (valid) {
                const response = await verificationCode({
                    email: values.email
                });

                if (response?.success) {
                    setStep(2);

                    showSuccessToast({ message: `Your verification code is sent successfully!` });
                }
                else {
                    showErrorToast({ message: response?.message });
                }
            }
        }
        catch (error) {
            console.log('Sign up with email', error);
        }

        setLoading(false);
    }, [values, valid]);

    const VerificationCodeConfirm = React.useCallback(async () => {
        setLoading(true);

        try {
            if (valid) {
                const response = await verificationCodeValidate({
                    email: values.email,
                    code: values.verificationCode
                });

                if (response?.success) {
                    setStep(3);

                    updateValue('token', response.data.token);

                    showSuccessToast({ message: `Your verification code confirmed successfully!` });
                }
                else {
                    showErrorToast({ message: response?.message });
                }
            }
        }
        catch (error) {
            console.log('Sign up with email', error);
        }

        setLoading(false);
    }, [values, valid]);

    const UpdateNewPassword = React.useCallback(async () => {
        setLoading(true);

        try {
            if (valid) {
                const response = await resetPassword({
                    email: values.email,
                    password: values.newPassword,
                    passwordConfirmation: values.newPasswordConfirmation,
                    token: values.token
                });

                if (response?.success) {
                    showSuccessToast({ message: `Your password is updated successfully!` });

                    navigate('/signin');
                }
                else {
                    showErrorToast({ message: response?.message });
                }
            }
        }
        catch (error) {
            console.log('Sign up with email', error);
        }

        setLoading(false);
    }, [values, valid]);

    const updateValid = React.useCallback((valuesNew: any) => {
        const validNew = !!(
            isValid.email(valuesNew.email)
        );

        setValid(validNew);
    }, []);

    const updateValue = React.useCallback((property: string, valueNew: any) => {
        setValues((previous: any) => {
            const valuesNew = {
                ...previous,
                [property]: valueNew
            };

            // Valid 
            updateValid(valuesNew);

            return valuesNew;
        });
    }, []);

    const parts = {
        form: (
            <Main>
                <FormHeader>
                    <Title>Forgot Password?</Title>

                    <Description>Already have an account? <Link onClick={() => navigate('/signin')}>Log in</Link> instead.</Description>
                </FormHeader>

                <FormMain>
                    <Label>
                        Email address

                        <Input
                            placeholder='e.g. hey@email.com'

                            type='email'

                            value={values.email || ''}

                            onChangeHandler={valueNew => updateValue('email', valueNew)}
                        />
                    </Label>
                </FormMain>

                <Column
                    style={{
                        gap: 32
                    }}
                >
                    <Button
                        onClick={SendResetInstructions}

                        style={{
                            width: 'unset',
                            alignSelf: 'flex-start'
                        }}

                        disabled={!valid || loading}
                    >
                        Send Reset Instructions
                    </Button>
                </Column>

                <Meta
                    style={{
                        marginTop: -8
                    }}
                >
                    For security reasons, we do NOT store your password. So rest assured that we will never send your password via email.
                </Meta>
            </Main>
        ),
        formVerficationConfirm: (
            <Main>
                <FormHeader>
                    <Title>Enter verification code</Title>

                    <Description>If the email you@work.com is valid, you will receive a verification code. Enter it below or <Link onClick={() => navigate('/signin')}>Log in</Link> instead.</Description>
                </FormHeader>

                <FormMain>
                    <Label>
                        Verification code

                        <Input
                            placeholder='Enter the code you received via email'

                            type='verificationCode'

                            value={values.verificationCode || ''}

                            onChangeHandler={valueNew => updateValue('verificationCode', valueNew)}
                        />
                    </Label>
                </FormMain>

                <Column
                    style={{
                        gap: 32
                    }}
                >
                    <Button
                        onClick={VerificationCodeConfirm}

                        style={{
                            width: 'unset',
                            alignSelf: 'flex-start'
                        }}

                        disabled={!(
                            values.verificationCode
                        ) || loading}
                    >
                        Submit Verification Code
                    </Button>
                </Column>

                <Meta
                    style={{
                        marginTop: -8
                    }}
                >
                    For security reasons, we do NOT store your password. So rest assured that we will never send your password via email.
                </Meta>
            </Main>
        ),
        updateNewPassword: (
            <Main>
                <FormHeader>
                    <Title>Reset password</Title>

                    <Description>Please enter a new password and confirm it, or <Link onClick={() => navigate('/signin')}>Log in</Link> instead.</Description>
                </FormHeader>

                <FormMain>
                    <Label>
                        <span>Password</span>

                        <Input
                            placeholder='Enter new password here'

                            type={showPassword === 'newPassword' ? 'text' : 'password'}

                            value={values.newPassword || ''}

                            onChangeHandler={valueNew => updateValue('newPassword', valueNew)}

                            style={{
                                paddingRight: 60
                            }}
                        />

                        <Show
                            onClick={() => setShowPassword((previous: any) => previous === 'newPassword' ? false : 'newPassword')}
                        >
                            {showPassword === 'newPassword' ? 'Hide' : 'Show'}
                        </Show>
                    </Label>

                    <Label>
                        <span>Password confirmation</span>

                        <Input
                            placeholder='Please confirm the password'

                            type={showPassword === 'newPasswordConfirmation' ? 'text' : 'password'}

                            value={values.newPasswordConfirmation || ''}

                            onChangeHandler={valueNew => updateValue('newPasswordConfirmation', valueNew)}

                            style={{
                                paddingRight: 60
                            }}
                        />

                        <Show
                            onClick={() => setShowPassword((previous: any) => previous === 'newPasswordConfirmation' ? false : 'newPasswordConfirmation')}
                        >
                            {showPassword === 'newPasswordConfirmation' ? 'Hide' : 'Show'}
                        </Show>
                    </Label>
                </FormMain>

                <Column
                    style={{
                        gap: 32
                    }}
                >
                    <Button
                        onClick={UpdateNewPassword}

                        style={{
                            width: 'unset',
                            alignSelf: 'flex-start'
                        }}

                        disabled={!(
                            values.newPassword &&
                            values.newPasswordConfirmation &&
                            values.newPassword === values.newPasswordConfirmation
                        ) || loading}
                    >
                        Submit New Password
                    </Button>
                </Column>

                <Meta
                    style={{
                        marginTop: -8
                    }}
                >
                    For security reasons, we do NOT store your password. So rest assured that we will never send your password via email.
                </Meta>
            </Main>
        ),
        reviews: (
            <Reviews>
                {reviews.map((item, index: number) => (
                    <Review
                        key={index}
                    >
                        <ReviewHeader>
                            <img
                                src={item.image}

                                alt={item.by}
                            />

                            <IconRating />
                        </ReviewHeader>

                        <ReviewQuote>
                            "{item.quote}"
                        </ReviewQuote>

                        <ReviewBy>
                            {item.by}
                        </ReviewBy>
                    </Review>
                ))}
            </Reviews>
        ),
        successImage: (
            <ImageSuccess
                src={ImageSigninSuccess}

                alt=''
            />
        )
    }

    return (
        <Root>
            <Column>
                {loaded && <>
                    {step === 1 && parts.form}

                    {step === 2 && parts.formVerficationConfirm}

                    {step === 3 && parts.updateNewPassword}
                </>}
            </Column>

            <Column>
                {parts.reviews}
            </Column>
        </Root>
    );
});

const Root = styled.div`
    display: flex;
    flex-direction: column; 
    min-height: 100%;

    & > * {
        flex: 1 1 50%; 
        min-height: 100%;
    } 

    & > div:nth-child(1) {
        padding: 100px 0; 
    }

    & > div:nth-child(2) {
        max-height: calc(100% - 80px);
        overflow-y: auto;
        padding: 40px 60px; 
        background: white;  
    }
    
    @media only screen and (min-width: 1024px) {
        flex-direction: row;
    }
`;

const Link = styled.span`
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    color: ${(props: any) => props.theme.primary600};
    transition: opacity 0.3s ease 0s; 

    &:hover {
        opacity: 0.54;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row; 
`;

const Column = styled.div`
    display: flex;
    flex-direction: column; 
`;

const Main = styled(Column)`
    width: 100%; 
    max-width: 400px; 
    margin: 0 auto;  
    gap: 32px;
`;

const FormHeader = styled(Column)`
    gap: 6px; 
`;

const FormMain = styled(Column)`
    gap: 16px; 
`;

const Title = styled.h1`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px; 
    color: ${(props: any) => props.theme.primaryColor}; 
    margin: 0px; 
`;

const Description = styled.p`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${(props: any) => props.theme.nonaryColor}; 
    margin: 0px; 
`;

const Reviews = styled(Column)`
    gap: 32px;
`;

const Review = styled(Column)`
    gap: 12px; 
`;

const ReviewHeader = styled(Row)` 
    align-items: center; 
    gap: 20px; 

    & > img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }
`;

const ReviewQuote = styled.h3`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: ${(props: any) => props.theme.primaryColor};
    margin: 0px;
`;

const ReviewBy = styled.p`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${(props: any) => props.theme.nonaryColor};
    margin: 0px;
`;

const Label = styled.label`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    gap: 6px; 

    font-family: NunitoSans;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px; 
    letter-spacing: 0.5px;
    text-transform: uppercase; 
    color: ${(props: any) => props.theme.nonaryColor}; 
    cursor: default;
`;

const Show = styled.span`
    position: absolute;
    bottom: 8px;
    right: 12px;
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${(props: any) => props.theme.primary600};
    cursor: pointer;
    user-select: none; 
`

const Button: any = styled.button<any>` 
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: ${(props: any) => props.theme.secondaryColor};
    border: none;
    outline: none;
    margin: 0;
    width: 100%;
    background: ${(props: any) => props.theme.primary600};
    padding: 0 10px;
    border-radius: 4px; 
    min-height: 34px;
    user-select: none; 
    transition: color .14s, background .14s, opacity .3s, transform .3s; 

    ${(props: any) => props.version === 'light' ? `
        color: ${props.color === 'default' ? props.theme.primaryColor : props.theme.primary600};
        background: ${props.theme.secondaryColor};
    ` : ''}

    ${(props: any) => props.disabled ? `
        cursor: default;
        color: ${props.theme.disabledState};
        background-color: ${props.theme.senaryColor}; 
        pointer-events: none;
    ` : ''}

    &:hover {
        opacity: 0.74;
    }

    &:active {
        transform: scale(0.97);
    }
`;

const ImageSuccess = styled.img`
    width: 100%;
    height: auto;
`;

const Meta = styled.p`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${(props: any) => props.theme.nonaryColor}; 
    margin: 0;
    margin-top: 24px; 
`

export default ForgotPassword; 
