import { FunctionComponent, useEffect, useState } from "react"
import { styles } from "../styles/themes/style";
import styled from "styled-components"

interface IStyledInputProps {
    $error?: boolean,
    $disabled?: boolean,
    $noBorder?: boolean
}

export interface IInputProps extends IStyledInputProps {
    type?: string;
    spellCheck?: boolean;
    placeholder?: string;
    prefilledValue?: string;
    onChangeHandler: (e: any) => void;
    style?: any;

    // Any other props 
    [p: string]: any;
}

const Input: FunctionComponent<IInputProps> = (props: IInputProps) => {
    const {
        prefilledValue,
        value,
        onChangeHandler,
        disabled,
        $disabled,
        ...other
    } = props;

    const [$value, setValue] = useState(prefilledValue);

    useEffect(() => {
        setValue(prefilledValue)
    }, [prefilledValue]);

    useEffect(() => {
        if (value !== $value) setValue(value);
    }, [value, $value]);

    const onChangeStateHandler = (valueNew: string) => {
        setValue(valueNew);
        onChangeHandler(valueNew);
    };

    const isDisabled = disabled || $disabled;

    return (
        <StyledInput
            value={$value}
            onChange={({ target: { value } }) => !isDisabled && onChangeStateHandler(value)}

            disabled={isDisabled}

            {...other}
        />
    )
}
const StyledInput = styled.input<IStyledInputProps>`
    font-family: NunitoSans;
    font-size: 14px;
    width: 100%;
    height: 34px;
    padding: 0 10px;
    line-height: 20px;
    outline: none;
    border-radius: 4px;
    ::placeholder { color: ${styles.colors.black300}; }
    color: ${({ theme: { primaryColor } }) => primaryColor};
    background-color: ${({ theme: { secondaryColor } }) => secondaryColor};
    ${({ theme: { quinaryColor } }) => `
        border: 2px solid ${quinaryColor};
        &:focus, &:hover { border: 2px solid ${styles.colors.primary600}; }`}

    ${({ $error }) => $error && `
        background-color: ${styles.colors.red200};
        border: 2px solid ${styles.colors.red600};
    `}
    
    ${({ $disabled, disabled }) => (disabled || $disabled) && `
        border: 2px solid #DAE0E8;
        background-color: #DAE0E8;
        pointer-events: none; 
        user-select: none;

        &:hover, &:focus{
            border: 2px solid #DAE0E8;
            background-color: #DAE0E8;
        }
    `}
    ${({ $noBorder }) => $noBorder && `
        border: none;
        &:focus, &:hover { border: none; }
    `}
    ::-webkit-inner-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
    ::-webkit-outer-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
    
`
export default Input