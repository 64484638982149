import { FunctionComponent } from 'react'
import { useAppStore } from '../../../stores/application/useAppStore'
import { useAppHandlers } from '../../../services/actions/app/useAppHandlers'

import CustomModal from '../CustomModal'

const MessageModal: FunctionComponent = () => {
    const { store: { modal } } = useAppStore()
    const { hideModal } = useAppHandlers()

    return (
        <CustomModal
            headingText={modal?.headingText}
            descriptionText={modal?.descriptionText}
            $center
            confirmButton={{
                text: 'Ok',
                bigButton: true,
                onClickHandler: hideModal
            }}
        />
    )
}

export default MessageModal