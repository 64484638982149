import { FunctionComponent, useState } from 'react'
import CustomModal from "../../../CustomModal";
import styled from "styled-components";

interface ICancelFlow {
    nextSection: () => void
    setState?: (e: any) => void
    previousSection: () => void
}

const WhatCanWeDoBetterSection: FunctionComponent<ICancelFlow> = ({ nextSection, setState, previousSection }) => {
    const [textarea, setTextarea] = useState('')

    const onSubmitHandler = () => {
        setState?.((previous: any) => Object.assign(previous, { ceo: textarea }))
        nextSection()
    }

    return (
        <CustomModal
            descriptionTextBold='Is there anything that we could have done better?'
            descriptionText='We value your input to improve LeadDelta.'
            logo
            $center
            divider
            closeButton
            cancelButton={{
                text: 'Go Back',
                mediumButton: true,
                onClickHandler: previousSection
            }}
            confirmButton={{
                text: 'Next',
                mediumButton: true,
                disable: textarea.length < 1,
                onClickHandler: onSubmitHandler
            }}
        >
            <Container>
                <StyledTextArea
                    placeholder='Our CEO evaluates every answer personally'
                    spellCheck='true'
                    maxLength={3000}
                    onInput={({ currentTarget: { value } }) => setTextarea(value)}
                />
                <StyledInputSpan> {textarea.length} / 3000 </StyledInputSpan>
            </Container>
        </CustomModal>
    )

}

const Container = styled.div`
    display: flex;
    flex-direction: column;
`

const StyledTextArea = styled.textarea`
    width: 100%;
    height: 85px;
    margin: 0;
    padding: 10px 7px 10px 7px;
    font-family: NunitoSans;
    font-size: 14px;
    border: 2px solid ${({ theme: { quinaryColor } }) => quinaryColor};
    border-radius: 4px; 
    white-space: pre-line;
    resize: none;
    transition: all .2s ease;

    &:focus, &:hover { outline: none; }

`

const StyledInputSpan = styled.span`
    color: rgb(140, 150, 173);
    font-size: 12px;
    align-self: flex-end;
`

export default WhatCanWeDoBetterSection