import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import LoaderCell from '../../../components/LoaderCell'
import TableCell from '../../../components/table/TableCell'
import TableData from '../../../components/table/TableData'
import TableRow from '../../../components/table/TableRow'

export const InvoceTableRowPreloader: FunctionComponent = () => {
    return (
        <TableRow>
            <TableCell $width="40%"><LoaderCell $width="150px" $height="20px" /></TableCell>
            <TableCell $width="20%"><LoaderCell $width="55px" $height="16px" /></TableCell>
            <TableCell $width="20%"><LoaderCell $width="50px" $height="20px" /></TableCell>
            <TableCell $width="20%"><LoaderCell $width="100px" $height="20px" /></TableCell>
        </TableRow>
    )
}

export const InvoceTableDataPreloader: FunctionComponent = () => {
    return (
        <TableData $maxHeight="249px">
            {Array(5).fill(undefined).map((x: any, index: number) => (
                <React.Fragment key={index}>
                    <InvoceTableRowPreloader />
                    <Divider />
                </React.Fragment>
            ))}
        </TableData>
    )
}

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: ${({ theme: { quinaryColor } }) => `${quinaryColor}`};
`

