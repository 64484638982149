import { TAppAction, TAppState, ActionType } from "../../interfaces/store/appStore";
import { makeStore } from "../makeStore";


const initialState: TAppState = {
    modal: undefined,
    toast: undefined,
    requestLoading: undefined,
    spinner: false
}

const reducer = (state: TAppState, action: TAppAction): TAppState => {
    switch (action.type) {
        case ActionType.SET_MODAL: return { ...state, modal: action.payload }
        case ActionType.SET_TOAST: return { ...state, toast: action.payload }
        case ActionType.SET_REQUEST_LOADING: return { ...state, requestLoading: action.payload }
        case ActionType.SET_SPINNER: return { ...state, spinner: action.payload }
        case ActionType.RESET_STORE: return { ...initialState }
        default: return state
    }
}

const { StoreProvider: AppStoreProvider, useStoreContext: useAppStore } = makeStore(reducer, initialState)

export { AppStoreProvider, useAppStore }