import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'

interface IContainerProps {
    $noPadding?: boolean,
    $width?: string
}

interface IBillingBoxProps extends IContainerProps {
    children: ReactNode
}

const BillingBox: FunctionComponent<IBillingBoxProps> = ({ children, ...props }) => {

    return (
        <Container {...props}>
            {children}
        </Container>
    )
}
const Container = styled.div<IContainerProps>`
    border-radius: 14px;
    width: 350px;
    ${({ $noPadding }) => !$noPadding && 'padding: 14px;'}
    background: ${({ theme: { secondaryColor } }) => secondaryColor};
    ${({ $width }) => $width?.length && `max-width: ${$width};`}
`

export default BillingBox