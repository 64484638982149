import { FunctionComponent, ReactNode } from "react"
import { styles } from "../styles/themes/style";
import styled from "styled-components"
import Text, { ITextProps } from "./Text";

interface IStyledActionTextProps extends ITextProps {
    danger?: boolean,
    cancel?: boolean,
    disabled?: boolean
}

interface IActionTextProps extends IStyledActionTextProps {
    onClickHandler: (e: any) => void
}

const ActionText: FunctionComponent<IActionTextProps> = ({ children, onClickHandler, disabled, danger, cancel, ...props }) => {

    return (
        <StyledActionText danger={danger} cancel={cancel} onClick={(e) => !disabled && onClickHandler(e)}>
            <Text $bold {...props}>
                {children}
            </Text>
        </StyledActionText>
    )
}
const StyledActionText = styled.div<IStyledActionTextProps>`
    span {
        color: ${styles.colors.primary600};
        ${({ danger }) => danger && `color: ${styles.colors.red600}`}
        ${({ cancel }) => cancel && `color: ${styles.colors.black300}`}

    }
    &:hover{
        cursor: pointer;
        span { 
            color: ${styles.colors.primary400} 
             ${({ danger }) => danger && `color: ${styles.colors.red500}`}
        }
    } 
    &:focus{
        span { color: ${styles.colors.primary300} }
        ${({ danger }) => danger && `color: ${styles.colors.red400}`}
    }

    ${({ disabled }) => disabled && `
        span, &:hover span, &:focus span{ 
            cursor: default;
            color: ${styles.colors.disabledState}
        }
    `}
`
export default ActionText