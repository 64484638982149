import { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { useAppHandlers } from '../../../../../services/actions/app/useAppHandlers'
import { useBillingRequest } from '../../../../../services/apis/billing/useBillingRequest'

import Button from '../../../../Button'
import DropdownItem from '../../../../dropdown/DropdownItem'
import DropdownMenu from '../../../../dropdown/DropdownMenu'
import Text from '../../../../Text'
import CustomModal from '../../../CustomModal'
import { styles } from '../../../../../styles/themes/style'

interface IPauseFlow {
    nextSection: () => void
}

const WhyDontWePauseSection: FunctionComponent<IPauseFlow> = ({ nextSection }) => {
    const [value, setValue] = useState('Choose...')
    const { setCancelFlow, hideModal } = useAppHandlers()
    const { pauseSubscriptionRequestHandler } = useBillingRequest()

    const onPauseSubscriptionHandler = async () => {
        const resumeAt = moment(moment()).add(Number(value.charAt(0)), 'M').unix()
        const data = await pauseSubscriptionRequestHandler(resumeAt)
        data.pausedUntil === resumeAt && nextSection()
    }
    return (
        <CustomModal
            logo
            $center
            closeButton
            divider
            descriptionTextBold='Why don’t we pause?'
            descriptionText='We will miss you but you can take a break from LeadDelta for a short while.'
            cancelButton={{
                text: 'Go back',
                bigButton: true,
                onClickHandler: hideModal
            }}
            confirmButton={{
                text: 'Decline offer',
                type: 'light-blue',
                bigButton: true,
                onClickHandler: setCancelFlow
            }}
        >
            <Container>
                <StyledText><Text $lighter>CHOOSE HOW LONG YOU WANT TO PAUSE</Text></StyledText>
                <DropdownMenu
                    title={value}
                >
                    <DropdownItem onClickHandler={(e) => setValue(e.target.innerText)}>1 month</DropdownItem>
                    <DropdownItem onClickHandler={(e) => setValue(e.target.innerText)}>2 months</DropdownItem>
                    <DropdownItem onClickHandler={(e) => setValue(e.target.innerText)}>3 months</DropdownItem>
                </DropdownMenu>
                <Button disabled={value === 'Choose...'} $type='blue' $bigButton onClickHandler={onPauseSubscriptionHandler}>Pause Subscription</Button>
            </Container>
        </CustomModal>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    & .dropdown {
        width: 100%;
        border: 2px solid ${({ theme: { quinaryColor } }) => `${quinaryColor};`};
        margin: 5px 0 20px 0;
        padding: 5px;

        &:hover { border: 2px solid ${(styles.colors.primary600)}; }
    }

    & .dropdown-menu {
        width: 100%;
        transform: translate3d(0px, 50px, 0px) !important;
    }

    & .dropdown-toggle {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        ${({ theme: { quaternaryColor } }) => `color: ${quaternaryColor};`}
    }
`
const StyledText = styled.div`
    place-self: baseline;
`

export default WhyDontWePauseSection