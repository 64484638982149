import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { useBillingStore } from '../../../stores/billing/useBillingStore'
import BillingBox from '../components/BillingBox'
import BillingPlans from '../components/BillingPlans'
import PaymentForm from '../components/PaymentForm'
import PaymentMethods from '../components/PaymentMethods'

interface IPaymentMethodSectionProps { }

const PaymentMethodSection: FunctionComponent<IPaymentMethodSectionProps> = () => {

    const { store: { setupIntent } } = useBillingStore()

    return (
        <Container>
            <StyledSection><PaymentMethods /></StyledSection>
            <StyledSection>
                {!setupIntent ?
                    <BillingBox>
                        Problem loading Stripe, please try again later
                    </BillingBox> :
                    <PaymentForm clientSecret={setupIntent?.client_secret} payNow={false} />}
            </StyledSection>
        </Container>
    )
}
const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
`
const StyledSection = styled.div`
    width: 350px;
    min-width: fit-content;
`

export default PaymentMethodSection