import { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import Text from '../../../Text'
import CustomModal from '../../CustomModal'
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../../../stores/auth/useAuthStore'
import { useAppHandlers } from '../../../../services/actions/app/useAppHandlers';

interface IPriceModel {
    latest_invoice_total?: number
    currency?: string,
    customerEmail?: string,
    interval?: string
}

const PaymentConfirmedModal: FunctionComponent = () => {
    const navigate = useNavigate()
    const { hideModal } = useAppHandlers()
    const { store: { user, subscription } } = useAuthStore()
    const [priceModel, setPriceModel] = useState<IPriceModel | undefined>()

    useEffect(() => { setPriceModel(subscription) }, [])

    return (
        <CustomModal
            checkmark
            $center
            descriptionTextBold={'Payment confirmed'}
            descriptionText={<span>Thank you, your payment has been sucessful and a confirmation email has been sent to <b>{priceModel?.customerEmail}</b></span>}
            confirmButton={{
                text: 'Ok',
                mediumButton: true,
                onClickHandler: () => { navigate('/billing/subscription'); hideModal() }
            }}
        >
            <TextWrapper>
                {priceModel && <>
                    <Text $heading5 $black>Total Payment Amount</Text>
                    <Text $heading3 $black>{priceModel.currency === 'eur' ? '€' : '$'}{((priceModel?.latest_invoice_total || 0) / 100).toFixed(2)}</Text>
                    <Text $paragraph3 $black>Billed {priceModel.interval}ly</Text>
                </>}
            </TextWrapper>
        </CustomModal>
    )
}

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export default PaymentConfirmedModal