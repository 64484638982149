import React, { FunctionComponent, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useAuthStore } from '../../../stores/auth/useAuthStore'
import { useBillingActions } from '../../../stores/billing/useBillingActions'
import { useBillingStore } from '../../../stores/billing/useBillingStore'
import BillingBox from '../components/BillingBox'
import BillingPlans from '../components/BillingPlans'
import PaymentForm from '../components/PaymentForm'

interface IPaymentSectionProps {
    payNow?: boolean
}

const PaymentSection: FunctionComponent<IPaymentSectionProps> = ({ payNow = true }) => {

    const { store: { setupIntent, selectedBillingPlan } } = useBillingStore()
    const { store: { validSubscription, trial, subscription, workspace } } = useAuthStore()
    const { setLicenceQuantity } = useBillingActions()
    const navigate = useNavigate()
    useEffect(() => {
        subscription && (validSubscription || subscription?.pausedUntil) && !trial && navigate("/billing/subscription")
        let calculateLicenceQuantity = subscription?.quantity && workspace?.seatsUsed && subscription?.quantity > workspace?.seatsUsed ?
            (subscription?.quantity ?? 1) :
            (workspace?.seatsUsed ?? 1)
        setLicenceQuantity(calculateLicenceQuantity)
    }, [subscription, workspace, selectedBillingPlan, validSubscription])

    //todo: add email
    return (
        <Container>
            <StyledSection><BillingPlans discountForm /></StyledSection>
            <StyledSection>
                {!setupIntent ?
                    <BillingBox>
                        Problem loading Stripe, please try again later
                    </BillingBox> :
                    <PaymentForm clientSecret={setupIntent?.client_secret} payNow={payNow} />}
            </StyledSection>
        </Container>
    )
}
const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
`
const StyledSection = styled.div`
    width: 350px;
    min-width: fit-content;
`

export default PaymentSection