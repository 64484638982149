import React, { FunctionComponent, SyntheticEvent } from "react"
import profileImage from '../assets/img/profile-photo.png'
import styled from 'styled-components'
import { useAuthStore } from "../stores/auth/useAuthStore"


interface IProfilePhotoProps {
    source?: string
}

const ProfilePhoto: FunctionComponent<IProfilePhotoProps> = ({ source }) => {

    //hooks
    const { store: { user } } = useAuthStore()
    //Event handlers
    const setFallbackImage = (event: SyntheticEvent<HTMLImageElement>) => {
        let target = event.target as HTMLImageElement
        target.src = profileImage
    }

    return (
        <StyledImage
            src={source || profileImage}
            alt='actions-button'
            style={{ width: '32px', borderRadius: '50%' }}
            onError={setFallbackImage}
        />
    )
}
const StyledImage = styled.img`
    width: 32px;
    border-radius: 50%;
`


export default ProfilePhoto