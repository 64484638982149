import { IUser, IUserResponse } from "../../../interfaces/models/user"
import { IAuthData } from "../../../interfaces/store/authStore"
import { useAuthActions } from "../../../stores/auth/useAuthActions"
import { sendMessage } from "../../../utils"
import { useAuthRequest } from "../../apis/auth/useAuthRequest"

export const useAuthHandlers = () => {

    const { resetAuthStore,
        setAuthData,
        setUser,
        setContext,
        setLifetime,
        setTrial,
        setValidSubscription,
        setWorkspace,
        setWorkspaceOwner,
        setSubscription,
        syncAccesToken,
        syncRefreshToken,
        syncExtensionId
    } = useAuthActions()
    const { getUser } = useAuthRequest()

    const setExtensionId = async (value: string) => {
        if (value) {
            let extensionId = syncExtensionId(value);

            setAuthData({ extensionId });
        }
    };

    const setAuthDataHandler = async (payload: IAuthData) => {
        let accessToken = syncAccesToken(payload?.accessToken as any)
        let refreshToken = syncRefreshToken(payload?.refreshToken as any)
        let extensionId = syncExtensionId(payload?.extensionId)

        if (accessToken) {
            setAuthData({ accessToken, refreshToken, extensionId })
        }

        getUserHandler({ accessToken, refreshToken })

        // add to the extension
        await sendMessage({
            action: 'putAppTokens',
            accessToken: payload.accessToken,
            refreshToken: payload.refreshToken
        });
    }

    const getUserHandler = async (payload?: IAuthData) => {
        const result = await getUser(payload)
        setUser(result?.user)
        setContext(result?.context === "personal" || !result?.context ? "individual" : result?.context) //todo: remove personal
        setLifetime(result?.hasCouponApplied || result?.user?.plan === "lifetime")
        setTrial(result?.isOnTrialPeriod > 0)
        setValidSubscription(result?.isValidSubscription)
        setWorkspace(result?.workspace)
        setWorkspaceOwner(result?.isWorkspaceOwner)
        result && setSubscription(result?.subscription)
    }

    return {
        setExtensionId,
        setAuthDataHandler,
        getUserHandler
    }
}