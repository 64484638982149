import { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import SvgFragment from '../fragments/SvgFragment'

interface ICheckboxProps {
    checked?: boolean
    checkboxId: string
    onClickHandler: (e: any) => void
}

const Check = (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10">
        <path fill="#FFF" fill-rule="evenodd" d="M4.954 9.003l6.708-6.426c.228-.218.228-.572 0-.79l-.824-.79c-.228-.218-.598-.218-.825 0l-5.471 5.24-2.555-2.446c-.227-.218-.597-.218-.825 0l-.824.79c-.228.218-.228.572 0 .79l3.791 3.632c.228.218.597.218.825 0z" />
    </svg>
);

const Icon: any = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;    
    width: 16px;
    height: 16px;
    border: 2px solid #8C96AD; 
    border-radius: 2px;

    ${(props: any) => props.checked ? `
        background-color: ${props.theme.primary600}; 
        border-color: ${props.theme.primary600}; 
    ` : ''}
`;

const Checkbox: FunctionComponent<ICheckboxProps> = ({ checked, checkboxId, onClickHandler }) => {

    return (
        <CheckboxWrapper id={checkboxId} onClick={(e) => onClickHandler(e)}>
            <Icon
                checked={checked}
            >
                {checked && Check}
            </Icon>
        </CheckboxWrapper>
    )
}

const CheckboxWrapper = styled.div`
    height: 27px;
    user-select: none; 
    cursor: pointer;
`

export default Checkbox