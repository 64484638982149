import { ReactNode } from 'react'

//Enums
export enum ActionType {
    SET_MODAL = "SET_MODAL",
    SET_TOAST = "SET_TOAST",
    SET_REQUEST_LOADING = "SET_REQUEST_LOADING",
    SET_SPINNER = "SET_SPINNER",
    RESET_STORE = "RESET_STORE"
}

//Interfaces
export interface IToast {
    type?: 'success' | 'danger' | 'info' | 'warning' | 'error'
    duration?: number
    message?: string
}

export interface IModal {
    type?: string
    headingText?: ReactNode
    descriptionText?: ReactNode
    descriptionTextBold?: ReactNode
    onConfirmHandler?: (e: any) => void
    onCancelHandler?: (e: any) => void
}

export interface IRequestLoading {
    type: string,
    loading: boolean
}

//States
export type TAppState = {
    modal?: IModal
    toast?: IToast
    requestLoading?: IRequestLoading
    counterValue?: number,
    spinner?: boolean
}

//Actions
interface IModalAction {
    type: ActionType.SET_MODAL
    payload: TAppState['modal']
}

interface IShowToastAction {
    type: ActionType.SET_TOAST
    payload: TAppState['toast']
}

interface IRequestLoadingAction {
    type: ActionType.SET_REQUEST_LOADING
    payload: TAppState['requestLoading']
}

interface ISetSpinner {
    type: ActionType.SET_SPINNER
    payload: TAppState['spinner']
}

interface IResetStoreAction {
    type: ActionType.RESET_STORE
}

export type TAppAction = IModalAction | IShowToastAction | IRequestLoadingAction | ISetSpinner | IResetStoreAction

//export type TDispatchType = (args: TAppState) => TAppAction