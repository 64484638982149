import { TCallbackFunction } from "../../../interfaces/common"
import { IUserResponse } from "../../../interfaces/models/user"
import { IAuthData } from "../../../interfaces/store/authStore"
import { useAuthStore } from "../../../stores/auth/useAuthStore"
import { IResponse, useAxios } from "../useAxios"

export const useAuthRequest = () => {
    const { sendRequest } = useAxios()
    const { store: { authData: { accessToken } } } = useAuthStore()

    const getUser = (params?: IAuthData): Promise<IUserResponse> => {
        return sendRequest({
            enabled: params ? !!params.accessToken : !!accessToken,
            slug: 'user',
            url: '/auth/me',
            method: 'GET',
            ...params && { additionalHeaders: { ...params } }
        })
    };

    const verificationCode = async (body: any) => {
        const response = await sendRequest({
            slug: 'verification-code',
            url: `/auth/verification-code`,
            method: 'POST',
            body,
            all: true
        });

        return response;
    };

    const verificationCodeValidate = async (body: any) => {
        const response = await sendRequest({
            slug: 'verification-code-validate',
            url: `/auth/verification-code/validate`,
            method: 'POST',
            body,
            all: true
        });

        return response;
    };

    const resetPassword = async (body: any) => {
        const response = await sendRequest({
            slug: 'reset-password',
            url: `/auth/reset-password`,
            method: 'POST',
            body,
            all: true
        });

        return response;
    };

    const invitePublic = async (inviteCode: any) => {
        const response = await sendRequest({
            slug: 'invite-public',
            url: `/invites/code/${inviteCode}`
        });

        return response;
    };

    const signInEmail = async (body: any) => {
        const response = await sendRequest({
            slug: 'login-email',
            url: `/auth/login`,
            method: 'POST',
            body
        });

        return response;
    };

    const signInGoogle = async (body: any) => {
        const response = await sendRequest({
            slug: 'login-google',
            url: `/auth/login/google`,
            method: 'POST',
            body
        });

        return response;
    };

    const signUpEmail = async (body: any, other?: any) => {
        const response = await sendRequest({
            slug: 'register-email',
            url: `/auth/register`,
            method: 'POST',
            body,

            ...other
        });

        return response;
    };

    const signUpGoogle = async (body: any, other?: any) => {
        const response = await sendRequest({
            slug: 'register-google',
            url: `/auth/register/google`,
            method: 'POST',
            body,

            ...other
        });

        return response;
    };

    return {
        getUser,
        verificationCode,
        verificationCodeValidate,
        resetPassword,
        invitePublic,
        signInEmail,
        signInGoogle,
        signUpEmail,
        signUpGoogle
    };
}