import { FunctionComponent, ReactNode } from "react"
import { styles } from "../styles/themes/style";
import styled from "styled-components"

interface IStyledTextProps {
    $bold?: boolean,
    $black?: boolean,
    $white?: boolean,
    $red?: boolean,
    $green?: boolean,
    $active?: boolean,
    $faded?: boolean,
    $lighter?: boolean,
    $paragraph1?: boolean,
    $paragraph3?: boolean,
    $heading1?: boolean,
    $heading2?: boolean,
    $heading3?: boolean,
    $heading4?: boolean,
    $heading5?: boolean,
    $label?: boolean,
    $description?: boolean,
    $inheritColor?: boolean,
    $capitalize?: boolean
}

export interface ITextProps extends IStyledTextProps {
    children?: ReactNode,
}

const Text: FunctionComponent<ITextProps> = ({ children, ...props }) => {
    return (
        <StyledParagraph {...props} >
            {children}
        </StyledParagraph>
    )
}
const StyledParagraph = styled.span<ITextProps>`
    font-family: NunitoSans;
    font-size: 14px;
    line-height: 20px;
    color: ${styles.colors.primary600};
    display: flex;
    gap: 4px;


    /* Colors */

    ${({ $black, theme: { primaryColor } }) => $black && `color: ${primaryColor};`}
    ${({ $white }) => $white && `color: ${styles.colors.white};`}
    ${({ $red }) => $red && `color: ${styles.colors.red600};`}
    ${({ $green }) => $green && `color: ${styles.colors.green600};`}
    ${({ $active }) => $active && `color: ${styles.colors.primary600};`}
    ${({ $lighter, theme: { nonaryColor } }) => $lighter && `color: ${nonaryColor};`}
    ${({ $faded, theme: { quaternaryColor } }) => $faded && `color: ${quaternaryColor};`}

    /* Paragraphs */
    ${({ $paragraph1 }) => $paragraph1 && `
        font-size: 16px;
        line-height: 24px;
    `}
    ${({ $paragraph3 }) => $paragraph3 && `
        font-size: 12px;
        line-height: 18px;
    `}

    /* Headings */
    ${({ $heading1 }) => $heading1 && `
        font-size: 36px;
        line-height: 40px;
    `}
    ${({ $heading2 }) => $heading2 && `
        font-size: 28px;
        line-height: 32px;
        font-weight: bold;
    `}
    ${({ $heading3 }) => $heading3 && `
        font-size: 24px;
        line-height: 28px;
        font-weight: bold;
    `}
    ${({ $heading4 }) => $heading4 && `
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;
    `}
    ${({ $heading5 }) => $heading5 && `
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
    `}
    ${({ $label }) => $label && `
        font-size: 11px;
        line-height: 16px;
        text-transform: uppercase;
    `}
    ${({ $description }) => $description && `
        font-size: 11px;
        line-height: 16px;
    `}
    ${({ $bold }) => $bold && 'font-weight: bold;'}
    ${({ $capitalize }) => $capitalize && 'text-transform: capitalize;'}
    ${({ $inheritColor }) => $inheritColor && `color: inherit;`}
`
export default Text