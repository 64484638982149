import { ISubscription, IUserSubscription } from "../models/subscription"
import { IUser, TContext } from "../models/user"
import { IWorkspace } from "../models/workspace"

//Enums
export enum ActionType {
    SET_AUTH_DATA = "SET_AUTH_DATA",
    SET_USER = "SET_USER",
    SET_CONTEXT = "SET_CONTEXT",
    SET_LIFETIME = "SET_LIFETIME",
    SET_TRIAL = "SET_TRIAL",
    SET_VALID_SUBSCRIPTION = "SET_VALID_SUBSCRIPTION",
    SET_WORKSPACE = "SET_WORKSPACE",
    SET_WORKSPACE_OWNER = "SET_WORKSPACE_OWNER",
    SET_SUBSCRIPTION = "SET_SUBSCRIPTION",
    RESET_STORE = "RESET_STORE"
}

//Interface
export interface IAuthData {
    accessToken?: string
    refreshToken?: string
    extensionId?: string
}

//States
export type TAuthState = {
    authData: IAuthData,
    context: TContext
    user?: IUser,
    workspace?: IWorkspace,
    lifetime: boolean,
    trial: boolean,
    validSubscription: boolean,
    workspaceOwner: boolean,
    subscription?: ISubscription | IUserSubscription,
}

//Actions
interface ISetAuthDataAction {
    type: ActionType.SET_AUTH_DATA
    payload: TAuthState['authData']
}

interface ISetUserData {
    type: ActionType.SET_USER
    payload: TAuthState['user']
}

interface ISetWorkspace {
    type: ActionType.SET_WORKSPACE
    payload: TAuthState['workspace']
}

interface ISetContext {
    type: ActionType.SET_CONTEXT
    payload: TAuthState['context']
}

interface ISetLifetime {
    type: ActionType.SET_LIFETIME
    payload: TAuthState['lifetime']
}

interface ISetTrial {
    type: ActionType.SET_TRIAL
    payload: TAuthState['trial']
}

interface ISetValidSubscription {
    type: ActionType.SET_VALID_SUBSCRIPTION
    payload: TAuthState['validSubscription']
}

interface ISetTeamOwner {
    type: ActionType.SET_WORKSPACE_OWNER
    payload: TAuthState['workspaceOwner']
}

interface ISetSubscriptionAction {
    type: ActionType.SET_SUBSCRIPTION
    payload: TAuthState['subscription']
}

interface IResetStoreAction {
    type: ActionType.RESET_STORE
}

export type TAuthAction = ISetAuthDataAction | ISetUserData
    | ISetContext | ISetLifetime | ISetTrial
    | ISetValidSubscription | ISetWorkspace | ISetTeamOwner
    | ISetSubscriptionAction | IResetStoreAction
