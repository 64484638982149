import { FunctionComponent, ReactNode, useState } from "react"
import styled from "styled-components"
import Text from "./Text";
import Tooltip from "../fragments/Tooltip";

interface IStyledFormControlProps {
    $orientation?: string,
    $width?: string
}

interface IFormControlProps extends IStyledFormControlProps {
    label?: string,
    description?: string,
    error?: string,
    children?: ReactNode,
    disabled?: boolean,
}

const FormControl: FunctionComponent<IFormControlProps> = ({ label, description, error, children, $orientation = "column", $width }) => {
    return (
        <StyledFormControl $orientation={$orientation} $width={$width}>
            {label && <Text $label $black>{label}</Text>}
            <StyledFormControlInput>
                {children}
                {error && <Text $description $red>{error}</Text>}
                {description && <Tooltip title={description} />}
            </StyledFormControlInput>
        </StyledFormControl>

    )
}
const StyledFormControl = styled.div<IStyledFormControlProps>`
    display: flex;
    width: ${({ $width }) => $width ? `${$width}` : `100%`};
    flex-direction: ${({ $orientation }) => $orientation}; 
    ${({ $orientation }) => $orientation === 'column' ? `gap: 6px;` : `
        justify-content: space-between;
    `}  
`
const StyledFormControlInput = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
    &:hover .LDtooltip { display: block; }
`
export default FormControl