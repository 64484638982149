import React, { FunctionComponent } from "react"
import DropdownItem from "../DropdownItem";
import DropdownMenu from "../DropdownMenu";
import { useAuthStore } from "../../../stores/auth/useAuthStore";
import Text from "../../Text"
import DropdownSeparator from "../DropdownSeparator";
import { useNavigate } from "react-router-dom";
import { useAuthActions } from "../../../stores/auth/useAuthActions";

interface IProfilePhotoDropdown { }

const ProfilePhotoDropdown: FunctionComponent<IProfilePhotoDropdown> = () => {
    const { store: { user } } = useAuthStore();
    const { logout } = useAuthActions();
    const navigate = useNavigate();

    const onLogout = () => {
        logout();

        navigate('/signin');
    };

    return (
        <DropdownMenu profileImage={true} $hideToggle={true} imgSource={user?.linkedinData?.profilePicture}>
            {user && <DropdownItem $biggerPadding $nonHoverable>
                {user?.linkedinData?.firstName && user?.linkedinData?.lastName && <Text $bold $black>{`${user.linkedinData.firstName} ${user.linkedinData.lastName}`}</Text>}
                <Text $lighter>{user?.email}</Text>
            </DropdownItem>}

            {user && <>
                <DropdownSeparator />

                <DropdownItem onClickHandler={() => navigate('/billing')}>
                    <Text $bold>Billing</Text>
                </DropdownItem>

                <DropdownSeparator />
            </>}

            <DropdownItem onClickHandler={() => window.open('https://help.leaddelta.com/en/', '_blank')} >
                <Text $bold>Need Help?</Text>
            </DropdownItem>
            <DropdownItem onClickHandler={() => window.open('https://onboarding.leaddelta.com/', '_blank')}>
                <Text $bold>Onboarding</Text>
            </DropdownItem>
            <DropdownItem onClickHandler={() => window.open('https://leaddelta.com/about/', '_blank')}>
                <Text $bold>About Us</Text>
            </DropdownItem>

            {user && <>
                <DropdownSeparator />

                <DropdownItem onClickHandler={onLogout}>
                    <Text $bold>Log out</Text>
                </DropdownItem>
            </>}
        </DropdownMenu>
    )
}


export default ProfilePhotoDropdown