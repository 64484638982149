import { v4 as uuidv4 } from 'uuid';

export const isValid = {
    email: (value: string) => String(value)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
};

export const googleLibrary: any = {
    oauth2: '',
    callback: ''
};

export const getID = () => uuidv4();

export const analytic = (body: any) => {
    const local = window.location.host.includes('localhost');

    if (!local) {
        try {
            (window as any).dataLayer = (window as any).dataLayer || [];

            (window as any).dataLayer.push({
                ...body
            });

            console.log('Analytic', body);
        }
        catch (error) {
            console.log('Analytic error', error);
        }
    }
    else {
        console.log('Analytic', body);
    }
};

export const getPageUrl = () => `${window.location.host}${window.location.pathname}`;

export const getPageTitle = () => {
    if (window.location.pathname === '/') return 'Billing';

    if (window.location.pathname.includes('/processing')) return 'Processing';

    if (window.location.pathname.includes('/subscription')) return 'Subscription';

    if (window.location.pathname.includes('/paymentMethods')) return 'Payment methods';

    if (window.location.pathname.includes('/updateSubscription')) return 'Update subscription';

    if (window.location.pathname.includes('/info')) return 'Info';

    if (window.location.pathname.includes('/signup')) return 'Sign up';

    if (window.location.pathname.includes('/integrations')) return 'Integrations';

    if (window.location.pathname.includes('/redirect')) return 'Redirect';
};
export const sendMessage = (data: any) => new Promise(resolve => {
    try {
        const runtime = (window as any).chrome.runtime;

        if (!runtime?.sendMessage) return resolve(false);

        runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, data, resolve);
    }
    catch (error) {
        console.log(error);

        resolve(false);
    }
});

export const URL_EXTENSION = `chrome-extension://${process.env.REACT_APP_EXTENSION_ID}/src/options/index.html`;

export const openExtension = ({ query }: any = {}) => {
    window.open(`${URL_EXTENSION}${query ? `#${query}` : ''}`, '_self');
};

export const checks = async () => {
    let value = true;

    try {
        // Extension 
        let response: any = await sendMessage({ action: 'status' });

        value = value && response?.result?.status === 'installed';

        response = await sendMessage({ linkedinAction: 'getLoggedInUser' });

        value = value && response?.result?.publicIdentifier;
    }
    catch (error) {
        return false;
    }

    return value;
};

export const signInCheck = async () => {
    const value = await checks();

    // auto open the extension 
    if (value) {
        openExtension();
    }
};
