import { FunctionComponent, ReactNode } from "react";
import { Modal } from "react-bootstrap";
import { styles } from "../../styles/themes/style";
import styled from "styled-components";

import { useAppHandlers } from "../../services/actions/app/useAppHandlers";

import Button from "../Button";
import Text from "../Text";
import leadDeltaLogo from '../../assets/img/header/lead-delta-logo.png'
import leadDeltaDarkLogo from '../../assets/img/header/lead-delta-dark-logo.png'
import SvgFragment from "../../fragments/SvgFragment";

interface IModal extends IModalHeader, IModalFooter {
    children?: ReactNode
    logo?: boolean
    checkmark?: boolean
    divider?: boolean
    confirmationMessage?: string
    cancelationMessage?: string
}

interface IModalHeader {
    headingText?: ReactNode
    descriptionText?: ReactNode
    descriptionTextBold?: ReactNode
    closeButton?: boolean
}

interface IModalFooter {
    $center?: boolean
    confirmButton?: IButtonStyle
    cancelButton?: IButtonStyle
}

interface IButtonStyle {
    text?: string
    type?: string
    disable?: boolean
    bigButton?: boolean
    mediumButton?: boolean
    onClickHandler?: (e: any) => void
}

const CustomModal: FunctionComponent<IModal> = ({ confirmationMessage, cancelationMessage, children, checkmark, divider, headingText, descriptionText, descriptionTextBold, closeButton, $center, confirmButton, cancelButton }) => {
    const { hideModal, setConfirmationModal } = useAppHandlers()

    const onConfirmationMessageHandler = (message: string) => {
        setConfirmationModal({
            descriptionTextBold: message,
            onCancelHandler: cancelButton?.onClickHandler,
            onConfirmHandler: confirmButton?.onClickHandler
        })
    }

    return (
        <>
            <StyledHeading>
                <Heading>
                    {checkmark && <Checkmark><SvgFragment type='checkmark' /></Checkmark>}
                    {(headingText && !checkmark) && headingText}
                    {(!headingText && !checkmark) && <StyledLogo />}
                </Heading>
                {closeButton && <X onClick={() => hideModal()}> <SvgFragment type="close" /> </X>}
            </StyledHeading>
            {divider && <Divider />}
            <TextWrapper>
                {descriptionTextBold && <Text $heading3 $black>{descriptionTextBold}</Text>}
                {descriptionText && <Text $lighter>{descriptionText}</Text>}
            </TextWrapper>
            {children && <StyledBody>{children}</StyledBody>}
            {divider && <Divider />}
            <StyledModalFooter $center={$center}>
                {cancelButton &&
                    <Button
                        disabled={cancelButton.disable}
                        $type={cancelButton.type ? cancelButton.type : 'grey'}
                        $bigButton={cancelButton.bigButton}
                        $mediumButton={cancelButton.mediumButton}
                        onClickHandler={(e) => {
                            cancelationMessage ? onConfirmationMessageHandler(cancelationMessage) : cancelButton.onClickHandler?.(e)
                        }}
                    >
                        {cancelButton.text}
                    </Button>
                }
                {confirmButton &&
                    <Button
                        disabled={confirmButton.disable}
                        $type={confirmButton.type ? confirmButton.type : 'blue'}
                        $bigButton={confirmButton.bigButton}
                        $mediumButton={confirmButton.mediumButton}
                        margin={cancelButton ? '0 0 0 15px' : '0'}
                        onClickHandler={(e) => {
                            confirmationMessage ? onConfirmationMessageHandler(confirmationMessage) : confirmButton.onClickHandler?.(e)
                        }}
                    >
                        {confirmButton.text}
                    </Button>
                }
            </StyledModalFooter>
        </>
    )
}

const StyledHeading = styled(Modal.Header)`
    padding: 24px;
    border-bottom: none;

    & .btn-close { padding-top: 18px; }
    & .btn-close:focus { box-shadow: none; }
    & .modal-title { ${({ closeButton }) => closeButton ? 'margin-left: auto' : 'margin: auto;'} }
`

const Heading = styled(Modal.Title)`
    display: flex;
    flex-direction: row;
    font-family: NunitoSans;
    font-size: 24px;
    font-weight: bold;
    color: ${({ theme: { primary } }) => primary ? styles.colors.black600 : styles.colors.darkTextcolor};
`

const Checkmark = styled.div`
    width: 86px;
    height: 86px;
    background-color: ${styles.colors.green200};
    border-radius: 50%;
`

const TextWrapper = styled.div`
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    & span:nth-child(2) { margin-top: 10px; }
`

const StyledBody = styled(Modal.Body)`
    padding: 0 24px 24px 24px;
`

const StyledLogo = styled.img`
    width: 141px;
    height: 32px;
    content: url('${({ theme: { primary } }) => primary ? leadDeltaLogo : leadDeltaDarkLogo}');
`

const X = styled.div`
    cursor: pointer;

    & svg {
        width: 12px;
        height: 12px
    }
`
const Divider = styled.div`
    border: 1px solid ${({ theme: { quinaryColor } }) => `${quinaryColor}`};
`
const StyledModalFooter = styled(Modal.Footer)`
    padding: 24px;
    border-top: none;

    ${({ $center }) => $center && `align-self: center;`}

    & button { margin: 0; }
`

export default CustomModal