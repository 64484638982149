import { FunctionComponent, ReactNode, useState } from "react"
import styled from "styled-components"
import Text from "./Text";
import Tooltip from "../fragments/Tooltip";
import { styles } from "../styles/themes/style";
import SvgFragment from "../fragments/SvgFragment";

interface IStyledTagProps {
    $color?: string,
    $borderColor?: string,
    $cutLeft?: boolean,
    $cutRight?: boolean
}

interface ITagProps extends IStyledTagProps {
    title: string,
    SVGtype?: string,
    onDestructiveClickHandler?: (e: any) => void
}

const Tag: FunctionComponent<ITagProps> = ({ SVGtype, title, onDestructiveClickHandler, ...props }) => {
    return (
        <StyledTagContainer {...props}>
            <SvgFragment type={SVGtype} />
            <StyledTag {...props} $cutLeft={!!onDestructiveClickHandler} ><Text $label $bold $inheritColor>{title}</Text></StyledTag>
            {onDestructiveClickHandler && <StyledDestructiveAction onClick={(e) => onDestructiveClickHandler(e)} {...props}>
                <SvgFragment type={'close'} />
            </StyledDestructiveAction>}
        </StyledTagContainer>

    )
}
const StyledTagContainer = styled.div<IStyledTagProps>`
    display: flex;
    height: fit-content;
    border-radius: 4px;
    border: 1px solid ${({ $borderColor, $color }) => $borderColor || $color || styles.colors.primary600}
`
const StyledTag = styled.div<IStyledTagProps>`
    display: flex;
    border-radius: 4px;
    ${({ $cutLeft }) => $cutLeft && 'border-radius: 4px 0px 0px 4px;'}
    ${({ $cutRight }) => $cutRight && 'border-radius: 0px 4px 4px 0px;'}
    padding: 0px 5px;
    word-wrap: break-word;
    max-width: 100%;
    height: fit-content;
    width: fit-content;
    color: ${({ $borderColor }) => $borderColor ?? styles.colors.white};
    background-color: ${({ $color }) => $color ?? styles.colors.primary600};
`
const StyledDestructiveAction = styled.div<IStyledTagProps>`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 4px 4px 0px;
    padding: 0px 5px;
    background-color: ${({ $color }) => $color ?? styles.colors.primary600};
    & svg path{
        fill: ${({ $borderColor }) => $borderColor ?? styles.colors.white};
    }
    &:hover, &:focus{
        opacity: 0.7;
    }
`
export default Tag