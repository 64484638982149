import { useAppActions } from "../../../stores/application/useAppActions"
import { ModalActionType } from "../../../components/modal/AppModal"
import { ReactNode } from "react"

export const useAppHandlers = () => {
    const { setToast, setModal, setSpinner } = useAppActions()

    interface IToastPayload {
        duration?: number,
        message: string
    }

    interface IModalPayload {
        headingText?: ReactNode
        descriptionText?: ReactNode
        descriptionTextBold?: ReactNode
        onConfirmHandler?: (e: any) => void
        onCancelHandler?: (e: any) => void
    }

    const showSuccessToast = (payload: IToastPayload) => setToast({ type: 'success', ...payload })
    const showInfoToast = (payload: IToastPayload) => setToast({ type: "info", ...payload })
    const showWarningToast = (payload: IToastPayload) => setToast({ type: 'warning', ...payload })
    const showErrorToast = (payload: IToastPayload) => setToast({ type: 'error', ...payload })
    const hideToast = () => setToast()

    const setModalSample = () => setModal({ type: ModalActionType.MODAL_SAMPLE })

    const setCancelFlow = () => setModal({ type: ModalActionType.MODAL_CANCEL_FLOW })
    const setPauseFlow = () => setModal({ type: ModalActionType.MODAL_PAUSE_FLOW })
    const setPaymentConfirmModal = () => setModal({ type: ModalActionType.PAYMENT_CONFIRM_MODAL })

    const setConfirmationModal = (payload: IModalPayload) => setModal({ type: ModalActionType.CONFIRMATION_MODAL, ...payload })
    const setDestructiveModal = (payload: IModalPayload) => setModal({ type: ModalActionType.DESTRUCTIVE_MODAL, ...payload })
    const setMessageModal = (payload: IModalPayload) => setModal({ type: ModalActionType.MESSAGE_MODAL, ...payload })
    const hideModal = () => setModal()

    const showSpinner = () => setSpinner(true)
    const hideSpinner = () => setSpinner(false)

    return {
        showSuccessToast,
        showInfoToast,
        showWarningToast,
        showErrorToast,
        hideToast,
        setModalSample,
        setCancelFlow,
        setPauseFlow,
        setPaymentConfirmModal,
        setConfirmationModal,
        setDestructiveModal,
        setMessageModal,
        hideModal,
        showSpinner,
        hideSpinner
    }
}