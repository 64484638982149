import { useEffect } from "react"
import { IBillingPlan } from "../../../interfaces/models/billingPlan"
import { IUpcomingPayment } from "../../../interfaces/response/upcomingPayment"
import { useAppActions } from "../../../stores/application/useAppActions"
import { useAuthActions } from "../../../stores/auth/useAuthActions"
import { useAuthStore } from "../../../stores/auth/useAuthStore"
import { useBillingActions } from "../../../stores/billing/useBillingActions"
import { useBillingStore } from "../../../stores/billing/useBillingStore"
import { IBillingRequestParams, IBuySubscriptionParams, IResumeSubscriptionParams, useBillingRequest } from "../../apis/billing/useBillingRequest"
import { useAuthHandlers } from "../auth/useAuthHandlers"

export const useBillingHandlers = () => {

    //hooks
    const { setToast } = useAppActions()
    const { setBillingPlans,
        setSelectedBillingPlan,
        setSetupIntent,
        setAppliedCoupon,
        setInvoices,
        setUpcomingPayment } = useBillingActions()
    const { store: { billingPlans, invoices } } = useBillingStore()
    const { getBillingPlansRequestHandler,
        getSetupIntentRequestHandler,
        applyCouponRequestHandler,
        buySubscriptionRequestHandler,
        getInvoicesRequestHandler,
        addPaymentMethodRequestHandler,
        calculateUpcomingPaymentRequestHandler,
        getCurrentPayingPriceRequestHandler,
        resumeSubscriptionRequestHandler } = useBillingRequest()
    const { store: { user, context, subscription, validSubscription } } = useAuthStore()
    const { setLifetime, setSubscription, setValidSubscription, setTrial } = useAuthActions()
    const { getUserHandler } = useAuthHandlers()



    //handlers
    const setSelectedBillingPlanHandler = (payload: string) => {
        //Calculate upcoming payment - apply coupon isto
        setSelectedBillingPlan(billingPlans.find((billingPlan: IBillingPlan) => billingPlan.interval === payload))
    }

    const getBillingPlansHandler = async () => {
        const result = await getBillingPlansRequestHandler()
        if (result && Array.isArray(result.plans)) {
            let { plans, version } = result;
            let selectedPlan;
            if (validSubscription && context === "workspace") {
                selectedPlan = plans.find((billingPlan: IBillingPlan) => billingPlan.priceId === subscription.priceId)
                version = selectedPlan ? selectedPlan.version : version
            }
            plans = result.plans.filter((billingPlan: IBillingPlan) => billingPlan.currency === (subscription?.currency ?? 'usd') && version === billingPlan.version)
            if (validSubscription && context !== "workspace") {
                selectedPlan = plans.find((billingPlan: IBillingPlan) => billingPlan.interval !== subscription.interval && version === billingPlan.version)
            }
            setBillingPlans(plans)
            if (!selectedPlan) selectedPlan = plans[0]
            setSelectedBillingPlan(selectedPlan)
        }
    }

    const getSetupIntentHandler = async () => {
        const result = await getSetupIntentRequestHandler()
        result && setSetupIntent(result)
    }

    const buySubscriptionHandler = async (buySubscriptionParams: IBuySubscriptionParams) => {
        const result = await buySubscriptionRequestHandler(buySubscriptionParams)
        result && setSubscription(result)
        /* result && setValidSubscription(true)
        result && setTrial(false) */
        user && getUserHandler()
        return result
    }

    const applyCouponHandler = async (couponCode: string) => {
        const result = await applyCouponRequestHandler(couponCode)
        result?.coupon && result?.type === 'stripe' && setAppliedCoupon({ ...result.coupon, couponCode })
        result?.type === 'native' && setLifetime(true)
    }
    const removeCouponHandler = async () => {
        setAppliedCoupon(undefined)
        setToast({ type: 'success', message: "Action succeeded!" })
    }
    const getInvoicesHandler = async (invoiceId?: string) => {
        const result = await getInvoicesRequestHandler(invoiceId)
        result && setInvoices([...invoiceId && invoices, ...result])
    }

    const addPaymentMethodHandler = async (paymentMethodId: string) => {
        const result = await addPaymentMethodRequestHandler(paymentMethodId)
        result && getUserHandler()
    }

    const calculateUpcomingPaymentHandler = async () => {
        const result = await calculateUpcomingPaymentRequestHandler()
        result && setUpcomingPayment(result)
    }

    const getCurrentPayingPriceHandler = async (priceId?: string) => {
        const result = await getCurrentPayingPriceRequestHandler(priceId)
        return result
    }

    const onResumeSubscriptionHandler = async (resume?: IResumeSubscriptionParams) => {
        const data = await resumeSubscriptionRequestHandler(resume)
        !data.pausedUntil && window.open(`/billing`, '_self')
    }

    return {
        setSelectedBillingPlanHandler,
        getBillingPlansHandler,
        getSetupIntentHandler,
        buySubscriptionHandler,
        applyCouponHandler,
        removeCouponHandler,
        getInvoicesHandler,
        addPaymentMethodHandler,
        calculateUpcomingPaymentHandler,
        getCurrentPayingPriceHandler,
        onResumeSubscriptionHandler
    }
}