import { FunctionComponent } from 'react'
import CustomModal from '../../../CustomModal'

const TakeShortBrakeSection: FunctionComponent = () => {

    return (
        <CustomModal
            logo
            $center
            closeButton
            divider
            descriptionTextBold='Sounds good, let’s take a short break. Here’s what happens next.'
            descriptionText='You can login to LeadDelta but you won’t be able to use it until the pause period is over. We’ll send you a reminder.'
            confirmButton={{
                text: 'Got it',
                bigButton: true,
                onClickHandler: () => window.open(`/billing`, '_self')
            }}
        />
    )
}

export default TakeShortBrakeSection