import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AppStoreProvider } from './stores/application/useAppStore';
import { AuthStoreProvider } from './stores/auth/useAuthStore';
import { QueryClient, QueryClientProvider } from 'react-query';
//import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <AppStoreProvider>
      <AuthStoreProvider>
        <App />
      </AuthStoreProvider>
    </AppStoreProvider>
  </QueryClientProvider>
);

/* // If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(); */
