import React from 'react';
import styled from 'styled-components';

import ImageShawn from '../../assets/img/signup/shawn.png';
import ImageQuadri from '../../assets/img/signup/quadri.png';
import ImagePia from '../../assets/img/signup/pia.png';
import ImageShane from '../../assets/img/signup/shane.png';
import ImageBirthday from '../../assets/img/birthday.png';

import { ReactComponent as IconRating } from '../../assets/img/signup/5-stars.svg';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthRequest } from '../../services/apis/auth/useAuthRequest';
import { useAuthActions } from '../../stores/auth/useAuthActions';
import { useAuthStore } from '../../stores/auth/useAuthStore';

const reviews = [
    {
        image: ImageShawn,
        quote: `Just implemented LeadDelta for the entire team at Autoklose. It allows us to better manage our LinkedIn network. Great app.`,
        by: `Shawn Finder, Co-founder of Autoklose`
    },
    {
        image: ImageQuadri,
        quote: `Amazing product. It helps me to grow and nurture my professional network. Often times I use it to communicate with my students and clients.`,
        by: `Quadri Oshibotu, Author and Founder of ProductHall`
    },
    {
        image: ImagePia,
        quote: `A tool that helps to better manage LinkedIn contacts with tags, notes, and more. For me in recruitment, in particular, it is a big help to structure my contacts. The tool is great!`,
        by: `Pia Teuner, HR Manager at Macaw`
    },
    {
        image: ImageShane,
        quote: `My teams have been using LeadDelta for +1yr to get a foot in the door. With workspaces, we can utilize low-hanging fruits and build a far more powerful internal network of supporters than our competitors.`,
        by: `Shane Starr, Head of Revenue at Fletch`
    }
];

const Invite = React.forwardRef((props: any, ref: any) => {
    const [step] = React.useState(1);
    const [loaded, setLoaded] = React.useState(false);
    const [invite, setInvite] = React.useState<any>();
    const [searchParams] = useSearchParams();
    const { invitePublic } = useAuthRequest();
    const authStore = useAuthStore();
    const { logout } = useAuthActions();
    const navigate = useNavigate();

    const refs = {
        auth: React.useRef<any>(authStore)
    };

    refs.auth.current = authStore;

    const init = React.useCallback(async () => {
        const inviteCode = searchParams.get('invite');

        const inviteValue = await invitePublic(inviteCode);

        if (!inviteValue.user) return navigate('/signin');

        // Sign out, and redirect to the same page  
        if (refs.auth.current.store.user) await logout(`${window.location.pathname}${window.location.search}`);

        // Update invite 
        setInvite(inviteValue);

        setLoaded(true);
    }, []);

    React.useEffect(() => {
        // init 
        init();
    }, []);

    const onClick = React.useCallback(() => {
        invite?.user === 'new' ? navigate(`/signup?invite=${invite.invite.code}`) : navigate(`/signin?invite=${invite.invite.code}`);
    }, [invite]);

    const parts = {
        form: (
            <Main>
                <FormHeader>
                    <Title>Hooray! <img src={ImageBirthday} alt='' style={{ width: 25, height: 25 }} /> You have joined a workspace on LeadDelta!</Title>

                    <Description
                        style={{
                            fontSize: 16
                        }}
                    >
                        Welcome aboard. You will now be able to collaborate and co-share your Social Capital with your workspace members. Share notes, collaborate on tags, apply filters and follow progress. Let's increase the value we get from one another. Stop managing deals. Start managing relationships.
                    </Description>
                </FormHeader>

                <Button
                    onClick={onClick}

                    style={{
                        width: 'unset',
                        alignSelf: 'flex-start'
                    }}
                >
                    Continue
                </Button>
            </Main>
        ),
        reviews: (
            <Reviews>
                {reviews.map((item, index: number) => (
                    <Review
                        key={index}
                    >
                        <ReviewHeader>
                            <img
                                src={item.image}

                                alt={item.by}
                            />

                            <IconRating />
                        </ReviewHeader>

                        <ReviewQuote>
                            "{item.quote}"
                        </ReviewQuote>

                        <ReviewBy>
                            {item.by}
                        </ReviewBy>
                    </Review>
                ))}
            </Reviews>
        )
    }

    return (
        <Root>
            <Column>
                {loaded && parts.form}
            </Column>

            <Column>
                {parts.reviews}
            </Column>
        </Root>
    );
});

const Root = styled.div`
    display: flex;
    flex-direction: column; 
    min-height: 100%;

    & > * {
        flex: 1 1 50%; 
        min-height: 100%;
    } 

    & > div:nth-child(1) {
        padding: 100px 0; 
    }

    & > div:nth-child(2) {
        max-height: calc(100% - 80px);
        overflow-y: auto;
        padding: 40px 60px; 
        background: white;  
    }
    
    @media only screen and (min-width: 1024px) {
        flex-direction: row;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row; 
`;

const Column = styled.div`
    display: flex;
    flex-direction: column; 
`;

const Main = styled(Column)`
    width: 100%; 
    max-width: 400px; 
    margin: 0 auto;  
    gap: 32px;
`;

const FormHeader = styled(Column)`
    gap: 12px; 
`;

const Title = styled.h1`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px; 
    color: ${(props: any) => props.theme.primaryColor}; 
    margin: 0px; 
`;

const Description = styled.p`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 400;   
    color: ${(props: any) => props.theme.nonaryColor}; 
    margin: 0px; 
    font-size: 16px; 
    line-height: 24px;
`;

const Reviews = styled(Column)`
    gap: 32px;
`;

const Review = styled(Column)`
    gap: 12px; 
`;

const ReviewHeader = styled(Row)` 
    align-items: center; 
    gap: 20px; 

    & > img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }
`;

const ReviewQuote = styled.h3`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: ${(props: any) => props.theme.primaryColor};
    margin: 0px;
`;

const ReviewBy = styled.p`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${(props: any) => props.theme.nonaryColor};
    margin: 0px;
`;

const Button: any = styled.button` 
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: ${(props: any) => props.theme.secondaryColor};
    border: none;
    outline: none;
    margin: 0;
    width: 100%;
    background: ${(props: any) => props.theme.primary600};
    padding: 0 10px;
    border-radius: 4px; 
    min-height: 34px;
    user-select: none; 
    transition: color .14s, background .14s, opacity .3s, transform .3s; 

    ${(props: any) => props.version === 'light' ? `
        color: ${props.color === 'default' ? props.theme.primaryColor : props.theme.primary600};
        background: ${props.theme.secondaryColor};
    ` : ''}

    ${(props: any) => props.disabled ? `
        cursor: default;
        color: ${props.theme.disabledState};
        background-color: ${props.theme.senaryColor}; 
        pointer-events: none;
    ` : ''}

    &:hover {
        opacity: 0.74;
    }

    &:active {
        transform: scale(0.97);
    }
`;

export default Invite; 
