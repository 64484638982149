import React, { useEffect } from 'react';
import styled from 'styled-components';

import ImageApp from '../../../../assets/img/app.png';

import { ReactComponent as IconStepSuccess } from '../../../../assets/img/step-success.svg';
import { ReactComponent as IconStepError } from '../../../../assets/img/step-error.svg';
import { ReactComponent as IconNewTab } from '../../../../assets/img/newtab.svg';
import { ReactComponent as IconRefresh } from '../../../../assets/img/refresh.svg';

import { sendMessage, openExtension } from '../../../../utils';

const Checks = React.forwardRef((props: any, ref: any) => {
    const [steps, setSteps] = React.useState({ extension: false, linkedin: false });
    const [loaded, setLoaded] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState('');

    const refs = {
        interval: React.useRef<any>()
    };

    const checkExtension = React.useCallback(async () => {
        setLoading(true);

        setMessage('');

        const response: any = await sendMessage({ action: 'status' });

        if (response?.result?.status === 'installed') {
            setSteps(previous => ({
                ...previous,

                extension: true
            }));

            // Check linkedin connection 
            checkLinkedinConnection();

            // Clear interval 
            clearInterval(refs.interval.current);
        }
        else {
            setSteps({ extension: false, linkedin: false });

            setMessage('extension');
        }

        setLoading(false);
    }, []);

    const checkLinkedinConnection = React.useCallback(async () => {
        setLoading(true);

        setMessage('');

        const response: any = await sendMessage({ linkedinAction: 'getLoggedInUser' });

        if (response?.result?.publicIdentifier) {
            setSteps(previous => ({
                ...previous,

                linkedin: true
            }));
        }
        else {
            setSteps(previous => ({
                ...previous,

                linkedin: false
            }));

            setMessage('linkedin');
        }

        setLoading(false);
    }, []);

    useEffect(() => {
        // Check extension 
        checkExtension();

        refs.interval.current = setInterval(checkExtension, 1400);

        setLoaded(true);

        return () => {
            clearInterval(refs.interval.current);
        };
    }, []);

    const toChromeStore = React.useCallback(() => {
        window.open(`http://url1530.leaddelta.com/ls/click?upn=WQjiRiZ5QXBrlipRDLcJClxxOdohzRaMOuO4cA-2BwU8mGUyWmJXhaSejB0L-2FzWeSXyeQASLvoLADDdm2hM8RN-2Fmb8AP-2FF8XgvUbJXJL7sOg0A-2FaA0KhONHiDG4lh3v-2FbLg1pavd9SGBTlQ36a1zJA7Q-3D-3DKPpv_bpBWbMOC6QRgMfkjC5vUAm5tc7MgjQYPBIgyJDgrwM3gOf26HrXUbWknSGQoRp6-2Fabp-2BLayI6Q2AxQJGAyRC6SVrbuGzXVQgToGQW7DjIL-2B0tZRJJNlsQS36qXOCnLN-2Bx7bZIQ6AV17R5I5nX-2BFqy8KH39UMEGrgsLHC73f-2BnMzLWvdJgulk-2BL2JflzzpK-2Fu2vJGRifepXvAB1Gl3MCde-2FT-2FJKBE986L18leU8UF254W5F8aNUiNL8QZXLx7QPjm1LmfaH99vQWqC72wONcSOG7IQRFj8aaLod5eOYNQcynaNPO0xG-2FA-2FfhXV1umiW3aWoKHkmaMJUhk1X3-2Fh4h-2FIw-3D-3D`, '_blank')?.focus();
    }, []);

    const parts = {
        form: (
            <Main>
                <Column
                    style={{
                        gap: 26
                    }}
                >
                    <Column>
                        <Title>Install Google Chrome extension</Title>

                        <Description
                            style={{
                                fontSize: 16,
                                marginTop: 8
                            }}
                        >
                            Follow the instructions to finish set up of your account.
                        </Description>

                        <Description
                            style={{
                                fontSize: 12,
                                fontStyle: 'italic',
                                fontWeight: 600,
                                marginTop: 6
                            }}
                        >
                            Note: LeadDelta works only on Google Chrome browser.
                        </Description>
                    </Column>

                    {/* Steps */}
                    <Column
                        style={{
                            gap: 24
                        }}
                    >
                        {/* Extension */}
                        <Column
                            style={{
                                gap: 12
                            }}
                        >
                            <Row
                                style={{
                                    gap: 8
                                }}
                            >
                                {steps.extension ? <IconStepSuccess /> : <IconStepError />}

                                <TitleStep><strong>Step 1:</strong> Install LeadDelta Extension for Chrome</TitleStep>
                            </Row>

                            <Row
                                style={{
                                    gap: 12
                                }}
                            >
                                <Button
                                    version='light'
                                    color='default'

                                    onClick={toChromeStore}
                                >
                                    <IconNewTab />

                                    <span>Install extension</span>
                                </Button>

                                <Button
                                    version='light'
                                    color='default'

                                    onClick={checkExtension}

                                    disabled={loading}
                                >
                                    <IconRefresh />

                                    <span>Check extension</span>
                                </Button>
                            </Row>
                        </Column>

                        {/* Linkedin */}
                        <Column
                            style={{
                                gap: 12
                            }}
                        >
                            <Row
                                style={{
                                    gap: 8
                                }}
                            >
                                {steps.linkedin ? <IconStepSuccess /> : <IconStepError />}

                                <TitleStep><strong>Step 2:</strong> Check if you are logged in to your LinkedIn</TitleStep>
                            </Row>

                            <Row
                                style={{
                                    gap: 12
                                }}
                            >
                                <Button
                                    version='light'
                                    color='default'

                                    onClick={checkLinkedinConnection}

                                    disabled={!steps.extension || loading}
                                >
                                    <IconRefresh />

                                    <span>Check LinkedIn Connection</span>
                                </Button>
                            </Row>
                        </Column>

                        {message && (
                            <Message>
                                {message === 'extension' ? 'Please make sure that you install LeadDelta Chrome extension and then click "Check Extension" to confirm.' : 'Make sure that you are logged in to your LinkedIn account that you want to connect.'}
                            </Message>
                        )}
                    </Column>
                </Column>

                <Button
                    onClick={openExtension}

                    disabled={!(steps.extension && steps.linkedin)}
                >
                    Go to extension
                </Button>
            </Main>
        ),
        successImage: (
            <ImageSuccess
                src={ImageApp}

                alt=''
            />
        )
    }

    return (
        <Root>
            <Column>
                {loaded && <>
                    {parts.form}
                </>}
            </Column>

            <Column>
                {parts.successImage}
            </Column>
        </Root>
    );
});

const Root = styled.div`
    display: flex;
    flex-direction: column; 
    min-height: 100%;

    & > * {
        flex: 1 1 50%; 
        min-height: 100%;
    } 

    & > div:nth-child(1) {
        padding: 100px 0; 
    }

    & > div:nth-child(2) {
        max-height: calc(100% - 80px);
        overflow-y: auto;
        padding: 60px 80px; 
        background: white;  
    }
    
    @media only screen and (min-width: 1024px) {
        flex-direction: row;
    }
`;

const Link = styled.span`
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    color: ${(props: any) => props.theme.primary600};
    transition: opacity 0.3s ease 0s; 

    &:hover {
        opacity: 0.54;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row; 
`;

const Column = styled.div`
    display: flex;
    flex-direction: column; 
`;

const Main = styled(Column)`
    width: 100%; 
    max-width: 400px; 
    margin: 0 auto;  
    gap: 32px;
`;

const Title = styled.h1`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px; 
    color: ${(props: any) => props.theme.primaryColor}; 
    margin: 0px; 
`;

const Description = styled.p`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${(props: any) => props.theme.nonaryColor}; 
    margin: 0px; 
`;

const Button: any = styled.button` 
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: ${(props: any) => props.theme.secondaryColor};
    border: none;
    outline: none;
    margin: 0; 
    background: ${(props: any) => props.theme.primary600};
    padding: 0 10px;
    border-radius: 4px; 
    min-height: 34px;
    align-self: flex-start;
    user-select: none; 
    gap: 6px;
    transition: color .14s, background .14s, opacity .3s, transform .3s; 

    ${(props: any) => props.version === 'light' ? `
        color: ${props.color === 'default' ? props.theme.primaryColor : props.theme.primary600};
        background: ${props.theme.secondaryColor};
    ` : ''}

    ${(props: any) => props.disabled ? `
        cursor: default;
        color: ${props.theme.disabledState};
        background-color: ${props.theme.senaryColor}; 
        pointer-events: none;

        & svg {
            opacity: 0.47;
        }
    ` : ''}

    &:hover {
        opacity: 0.74;
    }

    &:active {
        transform: scale(0.97);
    }
`;

const TitleStep = styled.p`
    color: ${(props: any) => props.theme.nonaryColor};
    font-size: 11px;
    font-family: NunitoSans;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase; 
    margin: 0; 
`

const ImageSuccess = styled.img`
    width: 100%;
    height: auto;
`;

const Message = styled.div` 
    color: ${props => props.theme.primaryColor};
    font-family: NunitoSans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 16px 20px; 
    background: #FFECC7; 
`;

export default Checks; 
