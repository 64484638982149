import React, { FunctionComponent, useContext, useEffect, useState } from "react"
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom"
import Spinner from "../components/Spinner"
import BillingPage from "../pages/billing/BillingPage"
import InfoSection from "../pages/billing/sections/InfoSection"
import PaymentMethodSection from "../pages/billing/sections/PaymentMethodSection"
import PaymentSection from "../pages/billing/sections/PaymentSection"
import ProcessingSection from "../pages/billing/sections/ProcessingSection"
import SubscriptionSection from "../pages/billing/sections/SubscriptionSection"
import UpdateSubscriptionSection from "../pages/billing/sections/UpdateSubscriptionSection"
import ContactPage from "../pages/contact/ContactPage"
import Redirect from "../pages/redirect/Redirect"
import { useAppHandlers } from "../services/actions/app/useAppHandlers"
import { useAuthHandlers } from "../services/actions/auth/useAuthHandlers"
import { useAppStore } from "../stores/application/useAppStore"
import { useAuthStore } from "../stores/auth/useAuthStore"
import { BillingStoreProvider } from "../stores/billing/useBillingStore"
import Home from "../pages/Home/Home"
import SignUp from "../pages/SignUp/SignUp"
import Rollout from "../pages/Rollout/Rollout"
import SignIn from "../pages/SignIn/SignIn"
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword"
import Invite from "../pages/Invite/Invite"
import { sendMessage } from "../utils"

interface IAppRouterProps { }

const AppRouter: FunctionComponent<IAppRouterProps> = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const { store } = useAuthStore()
    const { setAuthDataHandler, setExtensionId } = useAuthHandlers()

    const [userLoading, setUserLoading] = useState<boolean>(true) //user should not be fetched here but inside the app, this is for the billing because we need to fetch and see if user exists before fetching his own payment intent
    const { store: { requestLoading } } = useAppStore()
    const { setPaymentConfirmModal } = useAppHandlers()

    const {
        user
    } = store;

    const init = React.useCallback(async () => {
        // Get extension tokens
        const tokens: any = await sendMessage({
            action: 'getAppTokens'
        });

        const {
            accessToken,
            refreshToken
        } = tokens?.result || {};

        // update
        setAuthDataHandler({
            accessToken,
            refreshToken
        });
    }, []);

    React.useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        const appID = searchParams.get('appId');

        if (appID) setExtensionId(appID);

        const successModal = searchParams.get('successModal');

        if (successModal) setPaymentConfirmModal();
        
        if (appID || successModal) setSearchParams(undefined)
    }, [searchParams])

    useEffect(() => {
        requestLoading?.type === 'user' && setUserLoading(requestLoading.loading)
    }, [requestLoading]);


    // const runtime: any = (window as any)?.chrome?.runtime;

    // // runtime
    // React.useEffect(() => {
    //     let port: any;

    //     if (runtime) port = runtime.connect(process.env.REACT_APP_EXTENSION_ID);
    //     console.log(1, runtime, port);
    //     const method = (request: any, sender: any, sendResponse: any) => {
    //         console.log(1234, request);

    //         return Promise.resolve(true);
    //     };

    //     if (port) {
    //         port.onMessage.addListener(method);
    //     }

    //     // return () => {
    //     //     if (port) {
    //     //         port.onMessage.removeListener(method);
    //     //     }
    //     // };
    // }, [runtime]);

    console.log('AppRouter', store);

    //todo: later use spinner when navigating
    return (<>{userLoading ? <Spinner showHideSpinner={true} /> :
        <Routes>
            {user ? <>
                <Route path='/' element={<Home />} />

                <Route path="/invite" element={<Invite />} />

                <Route path="/billing" element={<BillingStoreProvider><BillingPage /></BillingStoreProvider>}>
                    <Route path="" element={<PaymentSection />} />
                    <Route path="processing" element={<ProcessingSection />} />
                    <Route path="subscription" element={<SubscriptionSection />} />
                    <Route path="paymentMethods" element={<PaymentMethodSection />} />
                    <Route path="updateSubscription" element={<UpdateSubscriptionSection />} />
                    <Route path="integrations" element={<Rollout />} />
                </Route>
            </> : <>
                <Route path="/signup" element={<SignUp />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/invite" element={<Invite />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
            </>}

            <Route path="/info" element={<InfoSection />} />
            <Route path="/redirect" element={<Redirect />} />

            <Route
                path="*"
                element={<Navigate to={user ? '/' : '/signin'} replace />}
            />
        </Routes>}</>)
}

export default AppRouter