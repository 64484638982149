import axios from "axios";
import { TCallbackFunction } from "../../interfaces/common";
import { IAuthData } from "../../interfaces/store/authStore";
import { useAppActions } from "../../stores/application/useAppActions";
import { useAuthActions } from "../../stores/auth/useAuthActions";
import { useAuthStore } from "../../stores/auth/useAuthStore";

export interface IAxiosParams {
  enabled?: boolean,
  slug: string
  url: string
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
  params?: Object
  body?: Object,
  payload?: Object,
  additionalHeaders?: IAuthData,
  clb?: TCallbackFunction<any>
  errorClb?: TCallbackFunction<any>
  displayToast?: boolean;
  all?: boolean;
}

export interface IResponse {
  data?: any,
  success?: boolean
}

export const useAxios = () => {
  const { store: { authData: { accessToken, refreshToken } } } = useAuthStore()
  const { syncAccesToken, setAuthData } = useAuthActions()
  const { setRequestLoading, setToast } = useAppActions()

  const sendRequest = async ({ slug, url, method = 'GET', params, body, payload, additionalHeaders, errorClb, clb, displayToast, enabled = true, all = false }: IAxiosParams) => {
    displayToast = displayToast ?? method !== 'GET';

    try {
      setRequestLoading({ type: slug, loading: true });

      if (!enabled) throw Error("Forbidden!");

      const { data, headers } = await axios.request({
        baseURL: process.env.REACT_APP_API_URL,
        url,
        method,
        params,
        data: body || payload,
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          ...(accessToken || additionalHeaders?.accessToken) && { Authorization: accessToken || additionalHeaders?.accessToken },
          ...(refreshToken || additionalHeaders?.refreshToken) && { 'refresh-token': refreshToken || additionalHeaders?.refreshToken },
        }
      })

      if (refreshToken && headers?.['new-access-token']) {
        const newAccessToken = syncAccesToken(headers?.['new-access-token']);

        setAuthData({ accessToken: newAccessToken, refreshToken });
      }

      //if (clb) clb(data?.data)
      if (!data || !data?.success) throw Error("There was a problem performing this action!");

      if (displayToast) setToast({ type: 'success', message: "Action succeeded!" });

      return all ? data : data?.data;
    }
    catch (error) {
      //console.error(error)
      const errorMessage = axios.isAxiosError(error) ? error?.response?.data?.message : "There was a problem performing this action!";

      if (displayToast) setToast({ type: 'error', message: errorMessage });

      if (errorClb) errorClb(errorMessage);

      return (error as any)?.response?.data;
    }
    finally {
      setRequestLoading({ type: slug, loading: false })
    }
  }
  return { sendRequest }
}