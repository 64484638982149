import { FunctionComponent, ReactNode } from "react"
import { styles } from "../../styles/themes/style";
import styled from "styled-components"

interface IStyledTableProps {
    $tableTemplateColumns?: string,
}

export interface ITableProps extends IStyledTableProps {
    children?: ReactNode
}

const Table: FunctionComponent<ITableProps> = ({ children, ...props }) => {
    return (<StyledTable>
        {children}
    </StyledTable>)
}
const StyledTable = styled.div<IStyledTableProps>`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2px;
`
export default Table