import { FunctionComponent } from 'react'
import { useAppHandlers } from '../../../../../services/actions/app/useAppHandlers';
import { useBillingRequest } from '../../../../../services/apis/billing/useBillingRequest';
import CustomModal from "../../../CustomModal";
import { ICancelationFlowQuestions } from '../CancelFlow';

interface IThanksForFeedbackSection {
    state?: ICancelationFlowQuestions
    nextSection: () => void
}

const ThanksForFeedbackSection: FunctionComponent<IThanksForFeedbackSection> = ({ nextSection, state }) => {
    const { hideModal } = useAppHandlers()
    const { deleteSubscriptionRequestHandler } = useBillingRequest()

    const onConfirmHandler = async () => {
        const data = await deleteSubscriptionRequestHandler(state)
        data.cancelDate && nextSection()
    }

    return (
        <CustomModal
            descriptionTextBold='Thanks for the feedback! Here’s what happens next.'
            descriptionText='Once your billing period expires you will lose access to your LeadDelta account and all its data: tags, notes, etc.'
            logo
            $center
            divider
            closeButton
            cancelButton={{
                text: 'I changed my mind',
                onClickHandler: hideModal
            }}
            confirmButton={{
                text: 'Cancel subscription',
                onClickHandler: onConfirmHandler
            }}
        >
        </CustomModal>
    )

}

export default ThanksForFeedbackSection