import { FunctionComponent, ReactNode } from "react"
import { styles } from "../../styles/themes/style";
import styled from "styled-components"
import Text from "../Text";
import TableCell from "./TableCell";

interface IStyledTableCellProps {
    $width?: string
}

export interface ITableCellProps extends IStyledTableCellProps {
    children?: ReactNode
}

const TableHeader: FunctionComponent<ITableCellProps> = ({ children, ...props }) => {
    return (<TableCell $header {...props}>
        <Text $label $black>{children}</Text>
    </TableCell>)
}
export default TableHeader