import { FunctionComponent } from "react";
import styled from "styled-components";
import LoaderCell from "../../../components/LoaderCell";

const PaymentInformationPreloader: FunctionComponent = () => {

    return (
        <PaymentInformation>
            <PaymentInformationLeft>
                <Info>
                    <LoaderCell $width="130px" />
                    <LoaderCell $width="50px" />
                </Info>
                <LoaderCell $width="120px" />
                <LoaderCell $width="220px" />
            </PaymentInformationLeft>
            <PaymentInformationRight>
                <LoaderCell $width="120px" $height="35px" />
                <LoaderCell $width="120px" $height="35px" />
            </PaymentInformationRight>
        </PaymentInformation>
    )
}

const PaymentInformation = styled.div`
    width: 730px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 10px;
    background: ${(props: any) => props.theme.secondaryColor};
    border: 2px solid ${(props: any) => props.theme.primary600};
    border-radius: 14px;
`
const PaymentInformationLeft = styled.div`
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly
`
const Info = styled.div`
    width: 220px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const PaymentInformationRight = styled.div`
    width: 250px;
    display: flex;
    justify-content: space-around;
`

export default PaymentInformationPreloader