

import { IFormProperty } from "../../interfaces/common"
import { IBillingPlan } from "../../interfaces/models/billingPlan"
import { IInvoice } from "../../interfaces/models/invoice"
import { ICoupon } from "../../interfaces/models/coupon"
import { ISubscription, IUserSubscription } from "../../interfaces/models/subscription"
import { IUpcomingPayment } from "../../interfaces/response/upcomingPayment"
import { ActionType, ISetupIntent } from "../../interfaces/store/billingStore"
import { useBillingStore } from "./useBillingStore"


export const useBillingActions = () => {
    const { dispatch } = useBillingStore()
    const setBillingPlans = (payload: IBillingPlan[]) => {
        return dispatch({
            type: ActionType.SET_BILLING_PLANS,
            payload
        })
    }

    const setSelectedBillingPlan = (payload: IBillingPlan) => {
        return dispatch({
            type: ActionType.SET_SELECTED_PLAN,
            payload
        })
    }

    const setSetupIntent = (payload?: ISetupIntent) => {
        return dispatch({
            type: ActionType.SET_SETUP_INTENT,
            payload
        })
    }

    const setAppliedCoupon = (payload?: ICoupon) => {
        return dispatch({
            type: ActionType.SET_APPLIED_COUPON,
            payload
        })
    }

    const setQuantityFormState = (payload?: IFormProperty) => {
        return dispatch({
            type: ActionType.SET_QUANTITY_FORM_STATE,
            payload
        })
    }

    const setLicenceQuantity = (payload?: number) => {
        return dispatch({
            type: ActionType.SET_LICENCE_QUANTITY,
            payload
        })
    }

    const setInvoices = (payload?: IInvoice[]) => {
        return dispatch({
            type: ActionType.SET_INVOICES,
            payload
        })
    }

    const setUpcomingPayment = (payload?: IUpcomingPayment) => {
        return dispatch({
            type: ActionType.SET_UPCOMING_PAYMENT,
            payload
        })
    }


    const resetBillingStore = () => {
        return dispatch({
            type: ActionType.RESET_STORE
        })
    }

    return {
        setBillingPlans,
        setSelectedBillingPlan,
        setSetupIntent,
        setAppliedCoupon,
        setQuantityFormState,
        setLicenceQuantity,
        setInvoices,
        setUpcomingPayment,
        resetBillingStore
    }
}


