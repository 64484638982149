import { createGlobalStyle } from "styled-components";

const Reset = createGlobalStyle`
    * {
        margin: 0px;
        padding: 0px;
        border: 0px;
        outline: none;
        font-size: 100%;
        background: transparent;
        box-sizing: border-box;
        touch-action: manipulation;
        -webkit-tap-highlight-color: transparent;
        -webkit-focus-ring-color: transparent;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    body {
        font-size: 0.875rem;
        font-family: NunitoSans, sans-serif;
        font-weight: normal;
        font-style: normal;
        position: relative;
        overflow-x: hidden;
        background-color: ${(props: any) => props.theme.tertiaryColor};
    }

    a {
        font-weight: bold; 
        text-decoration: none; 
        cursor: pointer; 
        color: #5964FF;
        transition: opacity .3s; 

        &:hover {
            opacity: .54;
        }
    }

    ::-webkit-scrollbar {
        width: 6px;
    }
    
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px 6px #dae0e8;
        border-radius: 10px;
    }
    
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px 6px ${(props: any) => props.theme.primary ? '#8c96ad' : '#4E515C'};
    }
`;

export default Reset; 
