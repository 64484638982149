import React, { FunctionComponent, ReactNode } from "react"
import { Dropdown } from "react-bootstrap"
import styled from "styled-components"
import { styles } from "../../styles/themes/style";

interface IStyledDropdownItemProps {
    $biggerPadding?: boolean,
    $nonHoverable?: boolean,
    $borderBottom?: boolean,
    $marginTop?: boolean,
    $marginBottom?: boolean, //todo: define these to be sent as values instead of booleans
}

interface IDropdownItemProps extends IStyledDropdownItemProps {
    children?: ReactNode,
    onClickHandler?: (e: any) => void //we do not need to be very strict here, it can depend on the element etc
}

const DropdownItem: FunctionComponent<IDropdownItemProps> = ({ children, onClickHandler, ...props }) => {
    return (
        <StyledDropdownItem onClick={onClickHandler} {...props}>
            {children}
        </StyledDropdownItem> /* todo: add more options such as children */
    )
}

const StyledDropdownItem = styled(Dropdown.Item) <IStyledDropdownItemProps>`
    line-height: 32px;
    padding: 6px 20px;
    font-family: NunitoSans;
    font-size: 14px;
    color: ${({ theme: { primaryColor } }) => primaryColor};

    ${({ $nonHoverable, theme: { primaryColor, senaryColor } }) => $nonHoverable && `&:hover, &:focus  { 
        color: ${primaryColor};
        background-color: ${senaryColor};
    };`}

    ${({ $nonHoverable, theme: { senaryColor } }) => !$nonHoverable && `&:hover, &:focus  { 
        color: ${styles.colors.primary600};
        background-color: ${senaryColor};
    };`}

    ${({ $biggerPadding }) => $biggerPadding && `padding: 16px 20px;`}
    ${({ $borderBottom, theme: { tertiaryColor } }) => $borderBottom && `border-bottom: 2px solid ${tertiaryColor};`}
    ${({ $marginBottom }) => $marginBottom && `margin-bottom: 10px;`}
    ${({ $marginTop }) => $marginTop && `margin-top: 10px;`}
`

export default DropdownItem