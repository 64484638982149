import { FunctionComponent } from "react";
import { useAppHandlers } from "../../../services/actions/app/useAppHandlers";

import CustomModal from "../CustomModal";

const ModalSample: FunctionComponent = () => {
    const { setMessageModal } = useAppHandlers()

    const onCloseHandler = () => console.log('On Close Handler')
    const onApplyHandler = () => {
        console.log('On Apply Handler')
        setMessageModal({
            headingText: 'Congratulations',
            descriptionText: 'You have successfully ...'
        })
    }

    const ModalBodyComponent = () => {
        return (
            <div>
                Modal body
            </div>
        )
    }

    return (
        <CustomModal
            headingText={'Title'}
            descriptionTextBold={'Here goes bold text...'}
            descriptionText={'Description...'}
            closeButton
            $center
            confirmationMessage={'Are you sure you want CONFIRM something....'}
            // cancelationMessage={'Are you sure you want CANCEL something....'}
            confirmButton={{
                text: "Apply",
                mediumButton: true,
                onClickHandler: onApplyHandler
            }}
            cancelButton={{
                text: "Close",
                mediumButton: true,
                onClickHandler: onCloseHandler
            }}
        >
            <ModalBodyComponent />
        </CustomModal>
    )
}

export default ModalSample