import { FunctionComponent } from "react";
import { useAppStore } from "../../stores/application/useAppStore";
import { useAppHandlers } from "../../services/actions/app/useAppHandlers";

import StyledModal from "./styledModals/StyledModal";
import ConfirmationModal from "./modals/ConfirmationModal";
import DestructiveModal from "./modals/DestructiveModal";
import ModalSample from "./modals/ModalSample";
import MessageModal from "./modals/MessageModal";

import CancelFlow from "./modals/cancelation-flow/CancelFlow";
import PaymentConfirmedModal from "./modals/billing/PaymentConfirmedModal";
import PauseFlow from "./modals/cancelation-flow/PauseFlow";

export enum ModalActionType {
    CONFIRMATION_MODAL = "CONFIRMATION_MODAL",
    DESTRUCTIVE_MODAL = "DESTRUCTIVE_MODAL",
    MESSAGE_MODAL = "MESSAGE_MODAL",
    MODAL_SAMPLE = "MODAL_SAMPLE",
    MODAL_CANCEL_FLOW = "MODAL_CANCEL_FLOW",
    MODAL_PAUSE_FLOW = "MODAL_PAUSE_FLOW",
    PAYMENT_CONFIRM_MODAL = "PAYMENT_CONFIRM_MODAL"
}

const AppModal: FunctionComponent = () => {
    const { store: { modal } } = useAppStore()
    const { hideModal } = useAppHandlers()

    if (!modal) return null

    let modalToShow

    switch (modal.type) {
        case ModalActionType.CONFIRMATION_MODAL:
            modalToShow = <ConfirmationModal />
            break
        case ModalActionType.DESTRUCTIVE_MODAL:
            modalToShow = <DestructiveModal />
            break
        case ModalActionType.MESSAGE_MODAL:
            modalToShow = <MessageModal />
            break
        case ModalActionType.MODAL_SAMPLE:
            modalToShow = <ModalSample />
            break
        case ModalActionType.MODAL_CANCEL_FLOW:
            modalToShow = <CancelFlow />
            break
        case ModalActionType.MODAL_PAUSE_FLOW:
            modalToShow = <PauseFlow />
            break
        case ModalActionType.PAYMENT_CONFIRM_MODAL:
            modalToShow = <PaymentConfirmedModal />
            break
    }

    return (
        <StyledModal
            show={modal}
            onHide={hideModal}
        >
            {modalToShow}
        </StyledModal>
    )
}

export default AppModal